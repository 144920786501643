<div *ngIf="allFlows" class="flex flex-col gap-12">
  <div class="max-w-[800px] relative">
    <img *ngIf="allFlows.type === 'stoneridge'" alt="Stoneridge"
      src="https://firebasestorage.googleapis.com/v0/b/sbs-app-b8eac.appspot.com/o/assets%2Ftachograph%2Fstoneridge.png?alt=media&token=64b2143e-5447-4c53-8690-a7ca31f0951b">
    <img *ngIf="allFlows.type === 'vdo'" alt="VDO"
      src="https://firebasestorage.googleapis.com/v0/b/sbs-app-b8eac.appspot.com/o/assets%2Ftachograph%2Fvdo.png?alt=media&token=be93165a-baa4-42da-86a8-906320832024">
    <div *ngIf="allFlows.type === 'stoneridge'" class="absolute top-[28%] left-[10%] text-white text-[40px] leading-8">
      <app-device-text [node]="currentNode"></app-device-text>
    </div>
    <div *ngIf="allFlows.type === 'vdo'" class="absolute top-[20%] left-[7%] text-black text-[30px] leading-8">
      <app-device-text [node]="currentNode"></app-device-text>
    </div>
  </div>

  <div>
    <button mat-flat-button (click)="actionOne()">
      1
    </button>

    <button mat-flat-button (click)="actionTwo()">
      2
    </button>

    <button mat-flat-button (click)="actionBack()">
      Back
    </button>

    <button mat-flat-button (click)="actionDown()">
      Down
    </button>

    <button mat-flat-button (click)="actionUp()">
      Up
    </button>

    <button mat-flat-button (click)="actionReturn()">
      Return
    </button>
  </div>

  <div *ngIf="dynamicItem">
    <h2>Dynamischer Inhalt</h2>
    <div class="flex flex-col">
      <div>{{ dynamicItem.value }}</div>
      <div>
        <mat-form-field>
          <mat-label>{{'device_steps_up' | translate}}</mat-label>
          <input matInput [ngModel]="dynamicItem.stepUp" type="number">
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'device_steps_down' | translate}}</mat-label>
          <input matInput [ngModel]="dynamicItem.stepDown" type="number">
        </mat-form-field>
      </div>
    </div>
  </div>

  <form *ngIf="!changingAction" [formGroup]="contentForm" (ngSubmit)="save()">
    <div class="flex flex-col">
      <app-input-form
        formControlName="hint"
        label="device_screen_hint"
      />
      <app-input-form
        formControlName="title"
        label="device_screen_title"/>
      <app-input-form
        formControlName="description"
        [rows]="10" label="device_screen_description"/>
      <app-input-form
        formControlName="action"
        label="device_screen_action"/>
      <button mat-flat-button type="submit">
        Speichern
      </button>
    </div>
  </form>
</div>
