import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import {CommonModule} from "@angular/common";
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {environment} from "../environments/environment";
import { NavComponent } from './layout/nav/nav.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import {AuthModule} from "./pages/auth/auth.module";
import {getAuth, provideAuth} from "@angular/fire/auth";
import {FIREBASE_OPTIONS} from "@angular/fire/compat";
import {DeviceModule} from "./pages/device/device.module";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {LanguageService} from "./services/language.service";
import {ELearningModule} from "./pages/e-learning/e-learning.module";
import {SimpleFormDialogModule} from "./common/simple-form-dialog/simple-form-dialog.module";
import {AppInitService} from "./services/app-init.service";
import { RequiredDirective } from './validators/required.directive';
import {QuillModule} from "ngx-quill";
import {HttpClientModule} from "@angular/common/http";
import {MatDialogModule} from "@angular/material/dialog";
import {ELearningReportsModule} from "./pages/e-learning-reports/e-learning-reports.module";
import {UserModule} from "./pages/user/user.module";
import {TranslationModule} from "./pages/translation/translation.module";

export function initializeAppFactory(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    DashboardComponent,
    RequiredDirective,
  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    AuthModule,
    DeviceModule,
    ELearningModule,
    ELearningReportsModule,
    UserModule,
    TranslationModule,
    SimpleFormDialogModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useClass: LanguageService
      }
    }),
    QuillModule.forRoot(),
  ],
  providers: [
    {
      provide: FIREBASE_OPTIONS,
      useValue: environment.firebase
    },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppInitService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
