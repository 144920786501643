export class FlowContent {
  id?: string;
  title: any;
  hint: string;
  description: string;
  action: string;
  updated?: Date;
  created?: Date;

  constructor(
    id: string,
    title: any,
    hint: string,
    description: string,
    action: string,
    updated: Date,
    created: Date
  ) {
    this.id = id;
    this.title = title;
    this.hint = hint;
    this.description = description;
    this.action = action;
    this.updated = updated;
    this.created = created;
  }
}

export const flowContentConverter = {
  toFirestore: (content: FlowContent) => {
    return {
      title: content.title,
      hint: content.hint,
      description: content.description,
      action: content.action,
      updated: content.updated,
      created: content.created
    }
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new FlowContent(snapshot.id, data.title, data.hint, data.description, data.action, data.updated, data.created);
  }
};
