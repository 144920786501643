import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ELearningService} from "../../../../services/e-learning.service";
import {requiredValidator} from "../../../../validators/required.directive";
import {FormBuilder} from "@angular/forms";
import {Chapter} from "../../../../models/chapter.model";
import {Simple} from "../../../../models/simple.model";

@Component({
  selector: 'app-chapter-form',
  templateUrl: './chapter-form.component.html',
  styleUrls: ['./chapter-form.component.scss']
})
export class ChapterFormComponent implements OnInit, OnDestroy {

  sub: any;
  trainingId: string = '';
  targetGroups: Simple[] = [];
  chapterId: string = '';
  chapterForm = this.fb.group({
    title: [{}, requiredValidator()],
    intro: [{}, requiredValidator()],
    description: [{}, requiredValidator()],
    targetGroups: [[]],
    image: [{}],
    order: [0],
    exam: [false],
    examMin: [75],
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eLearningService: ELearningService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.trainingId = params['id'];
      this.chapterId = params['chapterId'] || 'create';
      if (this.chapterId !== 'create') {
        this.eLearningService.getChapter(this.trainingId, this.chapterId).then(chapterSnap => {
          if (chapterSnap.exists()) {
            // TODO: Fix
            // @ts-ignore
            this.chapterForm.patchValue(chapterSnap.data());
          } else {
            this.router.navigate(['/e-learning']).then();
          }
        });
      }
      this.getTargetGroups();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getTargetGroups() {
    this.eLearningService.getTargetGroups().then(tg => {
      const targetGroups: Simple[] = [];
      tg.docs.map(t => {
        targetGroups.push(t.data());
      });
      this.targetGroups = targetGroups;
    });
  }

  saveChapter() {
    if (this.chapterForm.valid) {
      if (this.chapterId === 'create') {
        const chapter: Chapter =
          new Chapter('',
            this.chapterForm.value.title,
            this.chapterForm.value.intro,
            this.chapterForm.value.description,
            this.chapterForm.value.targetGroups || [],
            this.chapterForm.value.image,
            this.chapterForm.value.order || 0,
            this.chapterForm.value.exam || false,
            this.chapterForm.value.examMin || 75
          );
        this.eLearningService.addChapter(this.trainingId, chapter).then(r => {
          this.router.navigate(['/e-learning/training', this.trainingId, 'chapter', r.id]).then();
        });
      } else {
        const chapter: Chapter =
          new Chapter(this.chapterId,
            this.chapterForm.value.title,
            this.chapterForm.value.intro,
            this.chapterForm.value.description,
            this.chapterForm.value.targetGroups || [],
            this.chapterForm.value.image,
            this.chapterForm.value.order || 0,
            this.chapterForm.value.exam || false,
            this.chapterForm.value.examMin || 75
          );
        this.eLearningService.updateChapter(this.trainingId, chapter).then(() => {
          this.router.navigate(['/e-learning/training', this.trainingId, 'chapter']).then();
        });
      }
    }
  }

}
