import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ELearningService} from "../../../../services/e-learning.service";
import {Training} from "../../../../models/training.model";
import {Simple} from "../../../../models/simple.model";
import {ActivatedRoute, Router} from "@angular/router";
import {requiredValidator} from "../../../../validators/required.directive";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-training-form',
  templateUrl: './training-form.component.html',
  styleUrls: ['./training-form.component.scss']
})
export class TrainingFormComponent implements OnInit, OnDestroy {

  id: string = 'create';
  private sub: any;
  trainingCategories: Simple[] = [];
  targetGroups: Simple[] = [];
  training: Training | null = null;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private eLearningService: ELearningService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'] || 'create';
      if (this.id !== 'create') {
        this.eLearningService.getTraining(this.id).then(trainingSnap => {
          if (trainingSnap.exists()) {
            // TODO: Fix
            // @ts-ignore
            this.trainingForm.patchValue(trainingSnap.data());
          } else {
            this.router.navigate(['/e-learning']).then();
          }
        });
      }
    });
    this.getTrainingCategories();
    this.getTargetGroups();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getTrainingCategories() {
    this.eLearningService.getTrainingCategories().then(cat => {
      const trainingCategories: Simple[] = [];
      cat.docs.map(c => {
        trainingCategories.push(c.data());
      });
      this.trainingCategories = trainingCategories;
    });
  }

  getTargetGroups() {
    this.eLearningService.getTargetGroups().then(tg => {
      const targetGroups: Simple[] = [];
      tg.docs.map(t => {
        targetGroups.push(t.data());
      });
      this.targetGroups = targetGroups;
    });
  }

  trainingForm = this.fb.group({
    title: [{}, requiredValidator()],
    published: [false],
    price: [0],
    categories: [[], Validators.required],
    targetGroups: [[], Validators.required],
    intro: [{}, requiredValidator()],
    description: [{}, requiredValidator()],
    image: [{}],
  });

  saveTraining() {
    if (this.trainingForm.valid) {
      this.loading = true;
      if (this.id === 'create') {
        const training: Training =
          new Training('',
            this.trainingForm.value.title,
            this.trainingForm.value.published || false,
            this.trainingForm.value.price || 0,
            this.trainingForm.value.categories || [],
            this.trainingForm.value.targetGroups || [],
            this.trainingForm.value.intro,
            this.trainingForm.value.description,
            this.training?.certificatePath || '',
            this.training?.badgeUrl || '',
            this.trainingForm.value.image);
        this.eLearningService.addTraining(training).then(r => {
          this.router.navigate(['/e-learning/training/' + r.id]).then();
          this.loading = false;
          this.openSnackBar('Training wurde erstellt.');
        }).catch(e => {
          console.error('error', e);
          this.loading = false;
          this.openSnackBar('Training konnte nicht erstellt werden.');
        });
      } else {
        const training: Training =
          new Training(this.id,
            this.trainingForm.value.title,
            this.trainingForm.value.published || false,
            this.trainingForm.value.price || 0,
            this.trainingForm.value.categories || [],
            this.trainingForm.value.targetGroups || [],
            this.trainingForm.value.intro,
            this.trainingForm.value.description,
            this.training?.certificatePath || '',
            this.training?.badgeUrl || '',
            this.trainingForm.value.image);
        this.eLearningService.updateTraining(training).then(() => {
          this.router.navigate(['/e-learning/']).then();
          this.loading = false;
          this.openSnackBar('Training wurde aktualisiert.');
        }).catch(e => {
          console.error('error', e);
          this.loading = false;
          this.openSnackBar('Training konnte nicht aktualisiert werden.');
        });
      }
    }
  }

  syncProduct() {
    this.loading = true;
    this.eLearningService.syncProduct(this.id).then((response) => {
      console.log('response', response);
      this.loading = false;
      this.openSnackBar('Produkt wurde synchronisiert.');
    }).catch((error) => {
      console.log('error', error);
      this.loading = false;
      this.openSnackBar('Produkt konnte nicht synchronisiert werden.');
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 5 * 1000,
    });
  }

}
