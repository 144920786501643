<h1>
  <mat-icon class="cursor-pointer" routerLink="/e-learning">arrow_back</mat-icon>
  {{'e-learning_admin_settings' | translate}}
</h1>

<h2>{{'e-learning_training_categories' | translate}}</h2>

<div class="grid gap-4 grid-cols-1">
  <div>
    <button mat-raised-button (click)="addTrainingCategory()" class="demo-button">
      {{'e-learning_admin_training_category_add' | translate}}
    </button>
  </div>
  <div>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element">
          {{element.name | ct}}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element" class="!text-right">
          <button mat-icon-button><mat-icon>edit</mat-icon></button>
          <button mat-icon-button (click)="deleteTrainingCategory($event, element)"><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="cursor-pointer"
        (click)="updateTrainingCategory(row)"></tr>
    </table>
  </div>
  <div>
    <button mat-raised-button (click)="addTargetGroup()" class="demo-button">
      {{'e-learning_admin_target_group_add' | translate}}
    </button>
  </div>
  <div>
    <table mat-table [dataSource]="dataSourceGroups">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element">
          {{element.name | ct}}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element" class="!text-right">
          <button mat-icon-button><mat-icon>edit</mat-icon></button>
          <button mat-icon-button (click)="deleteTargetGroup($event, element)"><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="cursor-pointer"
        (click)="updateTargetGroup(row)"></tr>
    </table>
  </div>
</div>
