import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AngularFireAuth} from "@angular/fire/compat/auth";

interface UserTraining {
  userId: string;
  calculateTrainingStatus: boolean;
  chapters: {
    completed: boolean;
    completedPercents: number;
    startedAt: string;
    trainingId: string;
    trainingTitle: string;
    updated: string;
  };
}

interface User {
  uid: string;
  groupe: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  userTrainings$!: Observable<UserTraining[]>;
  users$!: Observable<User[]>;
  combinedData$!: Observable<any[]>;

  constructor(private afDb: AngularFireDatabase, private afAuth: AngularFireAuth) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        console.log('User is authenticated. UID:', user.uid);
        // Proceed with accessing the data
      } else {
        console.log('User is not authenticated.');
      }
    });
    this.userTrainings$ = this.afDb.list<UserTraining>('UserTrainings').valueChanges();
    this.users$ = this.afDb.list<User>('users').valueChanges();

    this.combinedData$ = this.combineData();
  }

  private combineData(): Observable<any[]> {
    return this.userTrainings$.pipe(
      map((userTrainings) =>
        userTrainings.map((training) => ({
          userId: training.userId,
          groupe: this.getUserGroup(training.userId),
          firstName: this.getUserFirstName(training.userId),
          lastName: this.getUserLastName(training.userId),
          trainingId: training.chapters.trainingId,
          startedAt: training.chapters.startedAt,
          completedPercents: training.chapters.completedPercents,
        }))
      )
    );
  }

  private getUserGroup(userId: string): Observable<string> {
    return this.users$.pipe(
      map((users) => {
        const user = users.find((user) => user.uid === userId);
        return user ? user.groupe : '';
      })
    );
  }

  private getUserFirstName(userId: string): Observable<string> {
    return this.users$.pipe(
      map((users) => {
        const user = users.find((user) => user.uid === userId);
        return user ? user.firstName : '';
      })
    );
  }

  private getUserLastName(userId: string): Observable<string> {
    return this.users$.pipe(
      map((users) => {
        const user = users.find((user) => user.uid === userId);
        return user ? user.lastName : '';
      })
    );
  }
}




