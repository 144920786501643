import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ContentTranslatePipe} from "./content-translate.pipe";



@NgModule({
  declarations: [
    ContentTranslatePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ContentTranslatePipe
  ]
})
export class ContentTranslateModule { }
