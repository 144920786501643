export class QuizOptions {
  id: string;
  question: any;
  answer: any;
  order: number;
  randomId: string;
  correct: boolean;

  constructor(id: string, question: any, answer: any, order: number, randomId: string, correct: boolean) {
    this.id = id;
    this.question = question;
    this.answer = answer;
    this.order = order;
    this.randomId = randomId;
    this.correct = correct;
  }
}
