import { Component, OnInit } from '@angular/core';
import {AngularFirestore, DocumentData} from "@angular/fire/compat/firestore";


@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  completedTrainingsCount = 0;
  inProcessTrainingsCount = 0;
  pieChartData!: any[];

  constructor(private firestore: AngularFirestore) { }

  ngOnInit() {
    this.fetchTrainingsData();
  }

  fetchTrainingsData() {
    this.firestore.collection('userTrainings').valueChanges().subscribe((trainings: any[]) => {
      this.completedTrainingsCount = trainings.filter(training => training.completed).length;
      this.inProcessTrainingsCount = trainings.filter(training => !training.completed).length;
      this.pieChartData = [
        { name: 'Completed', value: this.completedTrainingsCount },
        { name: 'In Process', value: this.inProcessTrainingsCount }
      ];
    });
  }


  onSelect(event: any): void {
    if (event) {
      const selectedSegment = event.name;
      console.log('Selected Segment:', selectedSegment);
    }
  }
}
