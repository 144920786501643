import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FlowService} from "../../../services/flow.service";
import {requiredValidator} from "../../../validators/required.directive";
import {FormBuilder} from "@angular/forms";
import {FlowContent} from "../../../models/flow-content.model";

@Component({
  selector: 'app-device-content',
  templateUrl: './device-content.component.html',
  styleUrls: ['./device-content.component.scss']
})
export class DeviceContentComponent implements OnInit, OnDestroy {

  sub: any;
  id: string = '';
  allFlows: any;
  currentNode: any;
  availableEdges: any[] = [];
  changingAction: boolean = false;
  dynamicItem: any;

  contentForm = this.fb.group({
    title: [{}],
    hint: [{}],
    description: [{}],
    action: [{}],
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private flowService: FlowService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
        this.getFlowData();
      } else {
        this.router.navigate(['/e-learning']).then();
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getFlowData() {
    this.flowService.getFlowData(this.id).then((flowSnap: any) => {
      console.log(flowSnap.data());
      this.allFlows = flowSnap.data();
      if (this.allFlows.nodes.length > 0) {
        this.setCurrentFlow(this.allFlows.nodes[0]);
      }
    });
  }

  setCurrentFlow(nextNode: any) {
    this.currentNode = nextNode;
    this.availableEdges = this.allFlows.edges.filter((edge: any) => edge.source === this.currentNode.id);
    this.flowService.getFlowContent(this.id, this.currentNode.id).then((contentSnap) => {
      if (contentSnap.exists()) {
        // @ts-ignore
        this.contentForm.patchValue(contentSnap.data());
      } else {
        this.contentForm.reset();
      }
      const dynamicValue = [];
      this.currentNode.data.content.lines.line1.forEach((item: any) => {
        if (item.dynamic) {
          this.dynamicItem = item;
        }
      });
      if (!this.dynamicItem) {
        this.currentNode.data.content.lines.line2.forEach((item: any) => {
          if (item.dynamic) {
            this.dynamicItem = item;
          }
        });
      }
      if (!this.dynamicItem) {
        this.currentNode.data.content.lines.mixed.forEach((item: any) => {
          if (item.dynamic) {
            this.dynamicItem = item;
          }
        });
      }
      this.changingAction = false;
    });
  }

  actionOne() {
    console.log("actionOne");
    this.handleActionTarget("1");
  }

  actionTwo() {
    this.handleActionTarget("2");
  }

  actionBack() {
    this.handleActionTarget("back");
  }

  actionDown() {
    this.handleActionTarget("down");
  }

  actionUp() {
    this.handleActionTarget("up");
  }

  actionReturn() {
    this.handleActionTarget("return");
  }

  handleActionTarget(action: string) {
    const targetEdge = this.availableEdges.find((edge: any) => edge.sourceHandle === action);
    if (targetEdge) {
      this.changingAction = true;
      this.setCurrentFlow(this.allFlows.nodes.find((node: any) => node.id === targetEdge.target));
    }
  }

  save() {
    const content: FlowContent = new FlowContent(
      this.currentNode.id,
      this.contentForm.value.title || '',
      this.contentForm.value.hint || '',
      this.contentForm.value.description || '',
      this.contentForm.value.action || '',
      new Date(),
      new Date()
    );
    this.flowService.saveFlowContent(this.id, this.currentNode.id, content).then((result) => {
      console.log(result);
    });
  }

}
