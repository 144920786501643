import { NgModule } from '@angular/core';
import { MediaComponent } from './media/media.component';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {ConfirmDialogModule} from "../../common/confirm-dialog/confirm-dialog.module";
import {MatListModule} from "@angular/material/list";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {SharedModule} from "../../common/shared/shared.module";
import {MatSelectModule} from "@angular/material/select";
import { DeviceContentComponent } from './device-content/device-content.component';
import { DeviceTextComponent } from './device-content/device-text/device-text.component';
import { DeviceTextLineComponent } from './device-content/device-text/device-text-line/device-text-line.component';
import {InputFormModule} from "../../common/input-form/input-form.module";

@NgModule({
  declarations: [
    MediaComponent,
    DeviceContentComponent,
    DeviceTextComponent,
    DeviceTextLineComponent
  ],
  imports: [
    SharedModule,
    MatIconModule,
    MatButtonModule,
    ConfirmDialogModule,
    MatListModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    InputFormModule,
    ReactiveFormsModule
  ]
})
export class DeviceModule { }
