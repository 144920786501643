import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sbs_admin_v2';

  constructor(
    translate: TranslateService
  ) {
    translate.setDefaultLang('de');
    translate.use('de');
  }
}
