<div *ngIf="contents.length > 0">
  <button mat-button color="primary" (click)="addContent()">
    {{ 'e-learning_admin_content_add' | translate }}
  </button>
  <div class="content-box flex">
    <div class="w-1/3 font-bold">
      {{ 'e-learning_content_name' | translate }}
    </div>
    <div class="w-1/3 font-bold">
      {{ 'e-learning_content_type' | translate }}
    </div>
    <div class="w-1/3">

    </div>
  </div>
  <div cdkDropList class="content-list" (cdkDropListDropped)="drop($event)">
    <div class="content-box flex" *ngFor="let content of contents" cdkDrag>
      <div class="w-1/3">
        {{content.order}}: {{content.title | ct}}
      </div>
      <div class="w-1/3">
        {{'e-learning_content_type_' + content.type | translate}}
      </div>
      <div class="w-1/3 text-right">
        <div class="flex content-end justify-end">
          <button mat-icon-button *ngIf="content.type === 'quiz'" (click)="goToQuizzes($event, content.id!)"><mat-icon>quiz</mat-icon></button>
          <button mat-icon-button (click)="goToContent($event, content.id!)"><mat-icon>edit</mat-icon></button>
          <button mat-icon-button (click)="deleteContent($event, content.id!)"><mat-icon>delete</mat-icon></button>
        </div>
      </div>
    </div>
  </div>
  <button mat-raised-button (click)="saveSort()">{{'e-learning_admin_content_save_sorting' | translate}}</button>
</div>
<div *ngIf="contents.length == 0">
  <div class="mt-5 mb-5">
    {{'e-learning_admin_content_no_content' | translate}}
  </div>
  <div>
    <button mat-raised-button color="primary" (click)="addContent()">
      {{ 'e-learning_admin_content_add' | translate }}
    </button>
  </div>
</div>
