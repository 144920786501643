import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TableComponent} from "./table/table.component";
import { ELearningReportsComponent } from './e-learning-reports.component';
import {PieChartComponent} from "./pie-chart/pie-chart.component";

@NgModule({
  declarations: [TableComponent, ELearningReportsComponent, PieChartComponent],
  imports: [
    CommonModule,
  ]
})
export class ELearningReportsModule { }
