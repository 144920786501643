<mat-card class="max-w-xs w-full" *ngIf="createNew || training">
  <mat-card-header [routerLink]=" createNew ? '/e-learning/training/create' : '/e-learning/training/' + training!.id + '/chapter'"
                   class="cursor-pointer !pb-3">
    <mat-card-title *ngIf="!createNew">{{training!.title | ct}}</mat-card-title>
    <mat-card-title *ngIf="createNew">{{'e-learning_admin_create' | translate}}</mat-card-title>
  </mat-card-header>
  <img class="bg-gray-200 cursor-pointer h-44 object-cover" mat-card-image
       [class.p-10]="createNew || !training!.image.de"
       [routerLink]=" createNew ? '/e-learning/training/create' : '/e-learning/training/' + training!.id + '/chapter'"
       [src]="!createNew && training!.image.de ? (training!.image | ct) : '/assets/images/logo.png'" alt="">
  <mat-card-content>
    <p *ngIf="training">
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button [routerLink]="'/e-learning/training/create'" mat-flat-button *ngIf="createNew" mat-button>{{'e-learning_admin_create' | translate}}</button>
    <button mat-flat-button *ngIf="!createNew" mat-button [routerLink]="['/e-learning/training/' + training!.id]">
      {{'e-learning_admin_edit' | translate}}</button>
    <button mat-flat-button *ngIf="!createNew" mat-button (click)="deleteTraining(training!)">
      {{'e-learning_admin_delete' | translate}}</button>
  </mat-card-actions>
</mat-card>
