import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {requiredValidator} from "../../../../validators/required.directive";
import {FormBuilder, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ELearningService} from "../../../../services/e-learning.service";
import {Quiz} from "../../../../models/quiz.model";
import {SelectionModel} from "@angular/cdk/collections";
import {MatTableDataSource} from "@angular/material/table";
import {QuizOptions} from "../../../../models/quiz-options.model";
import {SimpleFormDialogComponent} from "../../../../common/simple-form-dialog/simple-form-dialog.component";
import {Simple} from "../../../../models/simple.model";
import {MatDialog} from "@angular/material/dialog";
import {LanguageService} from "../../../../services/language.service";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmDialogComponent} from "../../../../common/confirm-dialog/confirm-dialog.component";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-quiz-form',
  templateUrl: './quiz-form.component.html',
  styleUrls: ['./quiz-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuizFormComponent implements OnInit, OnDestroy {
  sub: any;
  trainingId: string = '';
  chapterId: string = '';
  contentId: string = '';
  quizId: string = '';
  displayedSingleColumns: string[] = ['select', 'answer', 'actions'];
  selectDataSource = new MatTableDataSource<QuizOptions>([]);
  selection = new SelectionModel<QuizOptions>(false, []);
  options: QuizOptions[] = [];
  useFillOptions: boolean = false;

  quizForm = this.fb.group({
    title: [{}, requiredValidator()],
    type: [null, Validators.required],
    question: [{}, requiredValidator()],
    useFillOptions: [false],
    explanation: [{}],
    image: [{}],
    video: [{}],
    points: [0],
  });
  types: string[] = ['single', 'multi', 'drag', 'fill'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eLearningService: ELearningService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService,
    private languageService: LanguageService,
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.trainingId = params['id'];
      this.chapterId = params['chapterId'];
      this.contentId = params['contentId'];
      this.quizId = params['quizId'] || 'create';
      if (this.quizId !== 'create') {
        this.eLearningService.getQuiz(this.trainingId, this.chapterId, this.contentId, this.quizId).then(quizSnap => {
          if (quizSnap.exists()) {
            // @ts-ignore
            this.quizForm.patchValue(quizSnap.data());
            this.options = quizSnap.data().options;
            this.selectDataSource = new MatTableDataSource<QuizOptions>(this.options);
            const selection = this.options.filter(o => o.correct);
            this.onTypeChange(selection);
          } else {
            this.router.navigate(['/e-learning']).then();
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onTypeChange(selection?: QuizOptions[]) {
    const multi = this.quizForm.value.type! === 'multi';
    this.selection = new SelectionModel<QuizOptions>(multi, selection || []);
    if (['single', 'multi'].includes(this.quizForm.value.type!)) {
      this.displayedSingleColumns = ['select', 'answer', 'actions'];
    } else {
      this.displayedSingleColumns = ['question', 'answer', 'actions'];
    }
  }

  saveQuiz() {
    if (this.quizForm.valid) {
      const options: QuizOptions[] = [];
      this.options.forEach((o) => {
        o.correct = this.selection.selected.filter(s => s.id === o.id).length > 0;
        options.push(o);
      });
      if (this.quizId === 'create') {
        const quiz: Quiz =
          new Quiz('',
            this.quizForm.value.title,
            this.quizForm.value.type!,
            this.quizForm.value.question,
            this.quizForm.value.explanation,
            options,
            this.quizForm.value.useFillOptions!,
            this.quizForm.value.image,
            this.quizForm.value.video,
            this.quizForm.value.points || 0
          );
        this.eLearningService.addQuiz(this.trainingId, this.chapterId, this.contentId, quiz).then(r => {
          this.router.navigate(['/e-learning/training', this.trainingId, 'chapter', this.chapterId, 'content', this.contentId, 'quiz', r.id]).then();
        });
      } else {
        const quiz: Quiz =
          new Quiz(this.quizId,
            this.quizForm.value.title,
            this.quizForm.value.type!,
            this.quizForm.value.question,
            this.quizForm.value.explanation,
            options,
            this.quizForm.value.useFillOptions!,
            this.quizForm.value.image,
            this.quizForm.value.video,
            this.quizForm.value.points || 0
          );
        this.eLearningService.updateQuiz(this.trainingId, this.chapterId, this.contentId, quiz).then(() => {
          this.router.navigate(['/e-learning/training', this.trainingId, 'chapter', this.chapterId, 'content', this.contentId, 'quiz']).then();
        });
      }
    }
  }

  isAllMultiSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.selectDataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllMultiRows() {
    if (this.isAllMultiSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.selectDataSource.data);
  }

  onListDrop(event: CdkDragDrop<string[]>) {
    // Swap the elements around
    moveItemInArray(this.options, event.previousIndex, event.currentIndex);
  }

  addOption() {
    let data: any;
    if (['single', 'multi'].includes(this.quizForm.value.type!)) {
      data = {
        data: {
          data: new Simple(
            '',
            this.languageService.prepareLanguageObject(),
            'learning_quiz_option_answer'
          ),
          title: this.translate.instant('e-learning_admin_quiz_option_add'),
        }
      }
    } else {
      data = {
        data: {
          data: new Simple(
            '',
            this.languageService.prepareLanguageObject(),
            this.quizForm.value.type! == 'drag' ? 'e-learning_quiz_option_left' : 'e-learning_quiz_option_text',
            this.quizForm.value.type! == 'drag' ? 'e-learning_quiz_option_right' : 'e-learning_quiz_option_fields',
            this.quizForm.value.type! == 'drag' ? 'e-learning_quiz_option_right' : 'e-learning_quiz_option_fields',
          ),
          title: this.translate.instant('e-learning_admin_quiz_option_add'),
        }
      }
    }
    const dialogRef = this.dialog.open(SimpleFormDialogComponent, data);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        if (['single', 'multi'].includes(this.quizForm.value.type!)) {
          this.options.push(new QuizOptions(new Date().getTime().toString(), '', result.name, 0, "", false));
        } else if (this.quizForm.value.type! == 'drag') {
          const randomId = Math.floor(Math.random() * Math.floor(Math.random() * Date.now())) + "";
          this.options.push(new QuizOptions(new Date().getTime().toString(), result.name, result.name2, 0, randomId, false));
        } else {
          this.options.push(new QuizOptions(new Date().getTime().toString(), result.name, result.name2, 0, "", false));
        }
        this.selectDataSource.data = this.options;
      }
    });
  }

  updateOption(event: any, option: QuizOptions) {
    event.stopPropagation();
    let data: any;
    if (['single', 'multi'].includes(this.quizForm.value.type!)) {
      data = {
        data: {
          data: new Simple(
            '',
            option.answer,
            'e-learning_quiz_option_answer',
          ),
          title: this.translate.instant('e-learning_admin_quiz_option_update'),
        },
      }
    } else {
      data = {
        data: {
          data: new Simple(
            '',
            option.question,
            this.quizForm.value.type! == 'drag' ? 'e-learning_quiz_option_left' : 'e-learning_quiz_option_text',
            option.answer,
            this.quizForm.value.type! == 'drag' ? 'e-learning_quiz_option_right' : 'e-learning_quiz_option_fields',
          ),
          title: this.translate.instant('e-learning_admin_quiz_option_update'),
        },
      }
    }
    const dialogRef = this.dialog.open(SimpleFormDialogComponent, data);

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      console.log(result);
      if (result) {
        const filtered = this.options.filter((o) => o.id === option.id)[0];
        if (['single', 'multi'].includes(this.quizForm.value.type!)) {
          filtered.answer = result.name;
        } else {
          filtered.question = result.name;
          filtered.answer = result.name2;
        }
        this.selectDataSource.data = this.options;
      }
    });
  }

  deleteOption(event: any, optionId: string) {
    event.stopPropagation();
    this.openConfirmDeleteDialog(optionId);
  }

  openConfirmDeleteDialog(optionId: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: 'e-learning_admin_quiz_options_confirm_delete',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if (result && result.confirmed) {
        const filtered = this.options.filter((o) => o.id !== optionId);
        this.options = filtered;
        this.selectDataSource.data = filtered;
      }
    });
  }
}
