import { NgModule } from '@angular/core';
import {SimpleFormDialogComponent} from "./simple-form-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {InputFormModule} from "../input-form/input-form.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";

@NgModule({
  declarations: [
    SimpleFormDialogComponent
  ],
  imports: [
    SharedModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    InputFormModule
  ]
})
export class SimpleFormDialogModule { }
