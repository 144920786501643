import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-device-text-line',
  templateUrl: './device-text-line.component.html',
  styleUrls: ['./device-text-line.component.scss']
})
export class DeviceTextLineComponent {

  @Input() lineItems: any;
  @Input() type: string = 'two';

}
