import { NgModule } from '@angular/core';
import {ConfirmDialogComponent} from "./confirm-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {SharedModule} from "../shared/shared.module";



@NgModule({
  declarations: [
    ConfirmDialogComponent
  ],
  imports: [
    SharedModule,
    MatDialogModule,
    MatButtonModule
  ]
})
export class ConfirmDialogModule { }
