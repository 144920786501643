import {Component, inject, OnInit} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  private breakpointObserver = inject(BreakpointObserver);

  isGlobalAdmin = false;
  isTachographAdmin = false;
  isElearningAdmin = false;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.authService.afAuth.idTokenResult.subscribe(idToken => {
      if (idToken) {
        console.log(idToken.claims);
        if (idToken.claims) {
          if (idToken.claims["globalAdmin"]) {
            this.isGlobalAdmin = true;
            this.isTachographAdmin = true;
            this.isElearningAdmin = true;
          } else {
            this.isGlobalAdmin = false;
            this.isTachographAdmin = idToken.claims["admin_tachograph"];
            this.isElearningAdmin = idToken.claims["admin_e-learning"];
          }
        }
      }
    });
  }



  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  signOut() {
    this.authService.signOut().then(() => {
      this.router.navigate(['/login']).then();
    });
  }
}
