import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfirmDialogComponent} from "../../../common/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ELearningService} from "../../../services/e-learning.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Chapter} from "../../../models/chapter.model";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-chapters',
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.scss']
})
export class ChaptersComponent implements OnInit, OnDestroy {

  trainingId: string = '';
  private sub: any;
  chapters: Chapter[] = [];

  constructor(
    private dialog: MatDialog,
    private eLearningService: ELearningService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.trainingId = params['id'];
      if (this.trainingId) {
        this.getChapters();
      } else {
        this.router.navigate(['/e-learning']).then();
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getChapters() {
    this.eLearningService.getChapters(this.trainingId).then(chaptersSnap => {
      const chapters: Chapter[] = [];
      chaptersSnap.docs.map(chapterSnap => {
        chapters.push(chapterSnap.data());
      });
      console.log(chapters);
      this.chapters = chapters;
    });
  }

  addChapter() {
    this.router.navigate(['/e-learning/training', this.trainingId, 'chapter', 'create']).then();
  }

  goToChapter(event: any, chapterId: string) {
    event.stopPropagation();
    this.router.navigate(['/e-learning/training', this.trainingId, 'chapter', chapterId]).then();
  }

  deleteChapter(event: any, chapterId: string) {
    event.stopPropagation();
    this.openConfirmDeleteDialog(chapterId);
  }

  openConfirmDeleteDialog(chapterId: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: 'e-learning_admin_chapter_confirm_delete',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if (result && result.confirmed) {
        this.eLearningService.deleteChapter(this.trainingId, chapterId).then(() => {
          this.getChapters();
        });
      }
    });
  }

  addCertificate() {
    this.router.navigate(['/e-learning/training', this.trainingId, 'certificate']).then();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.chapters, event.previousIndex, event.currentIndex);
    this.chapters.forEach((chapter, index) => {
      chapter.order = index + 1;
    });
  }

  saveSort() {
    const promises: any[] = [];
    this.chapters.forEach((chapter) => {
      promises.push(new Promise((resolve) => {
        this.eLearningService.updateChapterOrder(this.trainingId, chapter).then(() => {
          resolve(null);
        });
      }));
    });
    Promise.all(promises).then(() => {
      this.openSnackBar(this.translate.instant('e-learning_admin_chapter_sort_success'));
      this.getChapters();
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 5 * 1000,
    });
  }
}
