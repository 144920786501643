<h2>Tachograph</h2>

<mat-list role="list">
  <mat-list-item *ngFor="let flow of flows" role="listitem">
    <span>{{flow.name}} ({{ flow.type }})</span>
    <button matListItemMeta mat-icon-button (click)="openFlowManager(flow.id)">
      <mat-icon class="mat-24">account_tree</mat-icon>
    </button>
    <button matListItemMeta mat-icon-button (click)="openContentEditor(flow.id)">
      <mat-icon class="mat-24">description</mat-icon>
    </button>
  </mat-list-item>
</mat-list>

<div>
  <br />
  <span class="font-bold">Neuen Flow für einen Tachograph hinzufügen:</span>
  <mat-form-field class="w-full">
    <mat-label>Name</mat-label>
    <input nbInput
           matInput
           [(ngModel)]="flowName"
           name="Name"
           id="input-flow-name"
           placeholder="Name"
           required
           autofocus>
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>Typ</mat-label>
    <mat-select [(ngModel)]="flowType" name="Type" id="input-flow-type" required>
      <mat-option *ngFor="let type of flowTypes" [value]="type">
        {{ type }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-raised-button
    [disabled]="!flowName || !flowType"
    (click)="createFlow()"
  >
    Neuen Flow im Manager erstellen
  </button>
</div>
<br />
<br />
<h2>Tachograph Icons</h2>
<div *ngIf="mediaFiles.length > 0" class="flex flex-wrap mb-2 gap-2">
    <div
      class="w-16"
      *ngFor="let mediaFile of mediaFiles; let i = index"
    >
      <div class="bg-white p-2">
        <div class="w-full place-content-center flex">
          <img
            class="h-16"
            [src]="mediaFile.url"
            [alt]="mediaFile.name"
            [title]="mediaFile.name"
          />
        </div>
        <button mat-button
          class="w-full"
          color="primary"
          (click)="deleteMedia(mediaFile)">
          <mat-icon aria-hidden="false" aria-label="Delete" fontIcon="delete"></mat-icon>
        </button>
      </div>
  </div>
</div>
<br />
<br />
<h2>Tachograph Media</h2>

<div class="row">
  <div class="col-8">
    <label>
      <input
        type="file"
        class="text-nowrap text-truncate"
        (change)="selectFile($event)"
      />
    </label>
  </div>

  <div class="col-4 mt-2">
    <button
      mat-raised-button
      class="btn btn-success btn-sm"
      color="primary"
      [disabled]="!selectedFiles"
      (click)="uploadMedia()"
    >
      Upload
    </button>
  </div>
</div>

<div *ngIf="currentFileUpload" class="progress mt-2">
  <div
    class="progress-bar progress-bar-info"
    role="progressbar"
    attr.aria-valuenow="{{ percentage }}"
    aria-valuemin="0"
    aria-valuemax="100"
    [style]="{ width: percentage + '%' }"
  >
    {{ percentage }}%
  </div>
</div>
