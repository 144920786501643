import {QuizOptions} from "./quiz-options.model";

export class Quiz {
  id?: string;
  title: any;
  type: "single" | "multi" | "drag" | "fill";
  question: any;
  explanation: any;
  options: QuizOptions[];
  useFillOptions: boolean;
  image?: any;
  video?: any;
  points: number;
  updated?: Date;
  created?: Date;

  constructor(id: string, title: any, type: "single" | "multi" | "drag" | "fill", question: any,
              explanation: any, options: QuizOptions[], useFillOptions: boolean, image: any,
              video: any, points: number, created?: Date, updated?: Date) {
    this.id = id;
    this.title = title;
    this.type = type!;
    this.question = question;
    this.explanation = explanation;
    this.options = options;
    this.useFillOptions = useFillOptions || false;
    this.image = image;
    this.video = video;
    this.points = points;
    this.created = created;
    this.updated = updated;
  }
}

export const quizConverter = {
  toFirestore: (quiz: Quiz) => {
    const options = JSON.parse(JSON.stringify(quiz.options));
    return {
      title: quiz.title,
      type: quiz.type,
      question: quiz.question,
      explanation: quiz.explanation,
      options: options,
      useFillOptions: quiz.useFillOptions || false,
      image: quiz.image,
      video: quiz.video,
      points: quiz.points,
      created: quiz.created || new Date(),
      updated: quiz.updated || new Date(),
    }
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    const type = data.type!;
    return new Quiz(snapshot.id, data.title, type, data.question, data.explanation, data.options, data.useFillOptions, data.image, data.video, data.points, data.created.toDate(), data.updated.toDate());
  }
};
