export class Training {
  id?: string;
  title: any;
  published: boolean;
  price: number;
  categories: string[];
  targetGroups: string[];
  intro: any;
  description: any;
  certificatePath?: string;
  badgeUrl?: string;
  image?: any;
  updated?: Date;
  created?: Date;

  constructor(
    id: string,
    title: any,
    published: boolean,
    price: number,
    categories: string[],
    targetGroups: string[],
    intro: any, description: any,
    certificatePath: string,
    badgeUrl: string,
    image: any,
    created?: Date,
    updated?: Date
  ) {
    this.id = id;
    this.title = title;
    this.published = published;
    this.price = price;
    this.categories = categories;
    this.targetGroups = targetGroups;
    this.intro = intro;
    this.description = description;
    this.certificatePath = certificatePath;
    this.badgeUrl = badgeUrl;
    this.image = image;
    this.created = created;
    this.updated = updated;
  }
}

export const trainingConverter = {
  toFirestore: (training: Training) => {
    return {
      title: training.title,
      published: training.published,
      price: training.price,
      categories: training.categories,
      targetGroups: training.targetGroups,
      intro: training.intro,
      description: training.description,
      certificatePath: training.certificatePath || '',
      badgeUrl: training.badgeUrl || '',
      image: training.image || '',
      created: training.created || new Date(),
      updated: training.updated || new Date(),
    }
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new Training(
      snapshot.id,
      data.title,
      data.published,
      data.price,
      data.categories,
      data.targetGroups,
      data.intro,
      data.description,
      data.certificatePath,
      data.badgeUrl,
      data.image,
      data.created.toDate(),
      data.updated.toDate()
    );
  }
};
