import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import { FileUpload } from "../../../models/file-upload.model";
import {finalize} from "rxjs/operators";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {InputFormComponent} from "../input-form.component";

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.scss']
})
export class UploadVideoComponent {

  private basePath = '/uploads';
  selectedVideo?: File;
  currentVideoUpload?: FileUpload;
  percentage = 0;

  @ViewChild("inputForm", { static: false }) inputFormComponent!: InputFormComponent;
  @Output() receivedDownloadUrl = new EventEmitter<string>();
  @Output() receivedVideoDuration = new EventEmitter<number>();

  constructor(private storage: AngularFireStorage) { }

  selectVideo(event: any): void {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').at(-1).toLowerCase();
      if (
        fileExtension &&
        ['mp4', 'm4v', 'avi', 'mov', 'mkv', 'wmv', 'flv', 'webm', 'ogg', 'ogv'].includes(fileExtension)
      ) {
        this.selectedVideo = selectedFile;
        const uploadButton = document.getElementById('uploadButton') as HTMLInputElement;
        if (uploadButton) {
          uploadButton.disabled = false;
        }
        this.getVideoDuration().then((duration) => {
          this.receivedVideoDuration.emit(Math.round(duration));
        });
      } else {
        console.log('Please select a valid video file (MP4, AVI, MOV, MKV, WMV, FLV, WebM).');
      }
    } else {
      console.log('No file selected.');
    }
  }

  async getVideoDuration(): Promise<number> {
    // @ts-ignore
    const url = URL.createObjectURL(this.selectedVideo);
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.muted = true;
      const source = document.createElement("source");
      source.src = url; //--> blob URL
      video.preload= "metadata";
      video.appendChild(source);
      video.onloadedmetadata = function(){
        resolve(video.duration);
      };
    });
  }

  pushFileToStorage(fileUpload: FileUpload) {
    const filePath = `${this.basePath}/${fileUpload.reference}/${fileUpload.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    uploadTask.snapshotChanges().pipe(
      finalize((): any => {
        storageRef.getDownloadURL().subscribe((downloadURL: any) => {
          fileUpload.url = downloadURL;
          this.receivedDownloadUrl.emit(downloadURL);
        });
      })
    ).subscribe();
    return uploadTask.percentageChanges();
  }

  uploadVideo(): void {
    if (this.selectedVideo) {
      this.currentVideoUpload = new FileUpload('', this.selectedVideo.name, '', this.selectedVideo, '/videos');
      this.pushFileToStorage(this.currentVideoUpload).subscribe(
        percentage => {
          this.percentage = Math.round(percentage ? percentage : 0);
        },
        error => {
          console.log(error);
        },
        () => {
          this.currentVideoUpload = undefined;
          this.percentage = 0;
          const fileInput = document.getElementById('fileInput') as HTMLInputElement;
          fileInput.value = '';
          this.selectedVideo = undefined;
        }
      );
    }
  }

}
