<h1>
  <mat-icon class="cursor-pointer" routerLink="/e-learning/training/{{trainingId}}/chapter">arrow_back</mat-icon>
  {{'e-learning_admin_quiz' | translate}}
</h1>

<div *ngIf="quizzes.length > 0">
  <button mat-button color="primary" (click)="addQuiz()">
    {{ 'e-learning_admin_quiz_add' | translate }}
  </button>
  <table mat-table [dataSource]="quizzes" class="mat-elevation-z0">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'e-learning_quiz_name' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.title | ct}}
      </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'e-learning_quiz_type' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{'e-learning_quiz_type_' + element.type | translate}}
      </td>
    </ng-container>

    <!-- Points Column -->
    <ng-container matColumnDef="points">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'e-learning_quiz_points' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.points}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element" class="!text-right">
        <button mat-icon-button><mat-icon>edit</mat-icon></button>
        <button mat-icon-button (click)="deleteQuiz($event, element.id!)"><mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
        class="cursor-pointer hover:!bg-red-100"
        *matRowDef="let row; columns: displayedColumns;"
        (click)="goToQuiz($event, row.id!)"></tr>
  </table>
</div>
<div *ngIf="quizzes.length == 0">
  <div class="mt-5 mb-5">
    {{'e-learning_admin_quiz_no_quiz' | translate}}
  </div>
  <div>
    <button mat-raised-button color="primary" (click)="addQuiz()">
      {{ 'e-learning_admin_quiz_add' | translate }}
    </button>
  </div>
</div>
