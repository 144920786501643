import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ELearningService} from "../../../services/e-learning.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../common/confirm-dialog/confirm-dialog.component";
import {Quiz} from "../../../models/quiz.model";

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit, OnDestroy {

  trainingId: string = '';
  chapterId: string = '';
  contentId: string = '';
  private sub: any;
  quizzes: Quiz[] = [];
  displayedColumns: string[] = ['title', 'type', 'points', 'actions'];

  constructor(
    private dialog: MatDialog,
    private eLearningService: ELearningService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.trainingId = params['id'];
      this.chapterId = params['chapterId'];
      this.contentId = params['contentId'];
      if (this.trainingId && this.chapterId && this.contentId) {
        this.getQuizzes();
      } else {
        this.router.navigate(['/e-learning']).then();
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getQuizzes() {
    this.eLearningService.getQuizzes(this.trainingId, this.chapterId, this.contentId).then(quizzesSnap => {
      const quizzes: Quiz[] = [];
      quizzesSnap.docs.map(chapterSnap => {
        quizzes.push(chapterSnap.data());
      });
      console.log(quizzes);
      this.quizzes = quizzes;
    });
  }

  addQuiz() {
    this.router.navigate(['/e-learning/training', this.trainingId, 'chapter', this.chapterId, 'content', this.contentId, 'quiz', 'create']).then();
  }

  goToQuiz(event: any, quizId: string) {
    event.stopPropagation();
    this.router.navigate(['/e-learning/training', this.trainingId, 'chapter', this.chapterId, 'content', this.contentId, 'quiz', quizId]).then();
  }

  deleteQuiz(event: any, quizId: string) {
    event.stopPropagation();
    this.openConfirmDeleteDialog(quizId);
  }

  openConfirmDeleteDialog(quizId: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: 'e-learning_admin_quiz_confirm_delete',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if (result && result.confirmed) {
        this.eLearningService.deleteQuiz(this.trainingId, this.chapterId, this.contentId, quizId).then(() => {
          this.getQuizzes();
        });
      }
    });
  }
}
