import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-device-text',
  templateUrl: './device-text.component.html',
  styleUrls: ['./device-text.component.scss']
})
export class DeviceTextComponent {

  @Input() node: any;

}
