import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  public calculateReadingTime(text: any){
    const wordsPerSecond = 200 / 60; // Average case.
    if (!text || !text.de) {
      return 0;
    }

    let textLength = text.de.split(" ").length;
    if (textLength > 0) {
      return Math.ceil(textLength / wordsPerSecond);
    } else {
      return 0;
    }
  }
}
