import { Component } from '@angular/core';
import {environment} from "../../../environments/environment";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {

  loginError = false;
  errorMessage = '';

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  env = environment;
  loginForm = new FormGroup({
    mail: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  signIn() {
    this.authService.signIn(this.loginForm.value.mail!, this.loginForm.value.password!)
      .then((result) => {
      console.log(result);
      this.loginError = false;
      this.router.navigate(['/']).then();
    }).catch((error) => {
      this.loginError = true;
      this.errorMessage = 'E-Mail und / oder Passwort falsch.'
    });
  }

}
