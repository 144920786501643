export class FileUpload {
  id: string;
  name: string;
  url: string;
  file?: File;
  reference: string

  constructor(id: string, name: string, url: string, file: File, reference: string) {
    this.id = id;
    this.name = name;
    this.url = url;
    this.file = file;
    this.reference = reference;
  }
}

const fileUploadConverter = {
  toFirestore: (fileUpload: FileUpload) => {
    return {
      id: fileUpload.id,
      name: fileUpload.name,
      url: fileUpload.url,
      file: fileUpload.file,
      reference: fileUpload.reference
    };
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new FileUpload(data.id, data.name, data.url, data.file, data.reference);
  }
};
