import { Injectable } from '@angular/core';
import {FileUpload} from "../models/file-upload.model";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  query
} from "@angular/fire/firestore";
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private basePath = '/uploads';
  db = getFirestore();
  fileRef = collection(this.db, 'files');

  constructor(
    private storage: AngularFireStorage
  ) { }

  pushFileToStorage(fileUpload: FileUpload) {
    const filePath = `${this.basePath}/${fileUpload.reference}/${fileUpload.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    uploadTask.snapshotChanges().pipe(
      finalize((): any => {
        storageRef.getDownloadURL().subscribe((downloadURL: any) => {
          fileUpload.url = downloadURL;
          this.saveFileData(fileUpload).then();
        });
      })
    ).subscribe();

    return uploadTask.percentageChanges();
  }

  private saveFileData(fileUpload: FileUpload) {
    delete fileUpload.file;
    return addDoc(this.fileRef, JSON.parse(JSON.stringify(fileUpload)));
  }

  deleteFile(fileUpload: FileUpload) {
    return this.deleteFileDatabase(fileUpload.id)
      .then(() => {
        this.deleteFileStorage(fileUpload.name);
      })
      .catch(error => console.log(error));
  }

  private deleteFileDatabase(id: string): Promise<void> {
    return deleteDoc(doc(this.fileRef, id));
  }

  private deleteFileStorage(name: string): void {
    const storageRef = this.storage.ref(this.basePath);
    storageRef.child(name).delete();
  }

}
