import {Component, OnDestroy, OnInit} from '@angular/core';
import {requiredValidator} from "../../../../validators/required.directive";
import {ActivatedRoute, Router} from "@angular/router";
import {ELearningService} from "../../../../services/e-learning.service";
import {FormBuilder, Validators} from "@angular/forms";
import {Content} from "../../../../models/content.model";
import {CommonService} from "../../../../services/common.service";

@Component({
  selector: 'app-content-form',
  templateUrl: './content-form.component.html',
  styleUrls: ['./content-form.component.scss']
})
export class ContentFormComponent implements OnInit, OnDestroy {
  sub: any;
  trainingId: string = '';
  chapterId: string = '';
  contentId: string = '';
  contentForm = this.fb.group({
    title: [{}, requiredValidator()],
    type: ['', Validators.required],
    imageType: ['above'],
    intro: [{}, requiredValidator()],
    description: [{}, requiredValidator()],
    content: [{}],
    image: [{}],
    video: [{}],
    quiz: [''],
    order: [0],
    time: [0],
  });
  types: string[] = ['text', 'image', 'video', 'quiz'];
  imageTypes: string[] = ['above', 'below', 'left', 'right'];
  textReadingTime: number = 0;
  videoTime: number = 0;
  descriptionReadingTime: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eLearningService: ELearningService,
    private fb: FormBuilder,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.trainingId = params['id'];
      this.chapterId = params['chapterId'];
      this.contentId = params['contentId'] || 'create';
      if (this.contentId !== 'create') {
        this.eLearningService.getContent(this.trainingId, this.chapterId, this.contentId).then(contentSnap => {
          if (contentSnap.exists()) {
            this.contentForm.patchValue(contentSnap.data());
            this.videoTime = contentSnap.data().videoTime || 0;
          } else {
            this.router.navigate(['/e-learning']).then();
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onTextEditorValueChange(val: any) {
    this.textReadingTime = this.commonService.calculateReadingTime(val);
    this.contentForm.controls.time.setValue(this.textReadingTime + this.descriptionReadingTime + this.videoTime);
  }

  onDescriptionValueChange(val: any) {
    this.descriptionReadingTime = this.commonService.calculateReadingTime(val);
    this.contentForm.controls.time.setValue(this.textReadingTime + this.descriptionReadingTime + this.videoTime);
  }

  onReceiveVideoLength(length: number) {
    this.videoTime = length;
    this.contentForm.controls.time.setValue(this.textReadingTime + this.descriptionReadingTime + this.videoTime);
  }

  saveContent() {
    if (this.contentForm.valid) {
      let time = this.contentForm.value.time;
     if (this.contentForm.value.type === 'quiz') {
        // will be calculated in functions when quiz is created or updated
        time = 0;
      }

      if (this.contentId === 'create') {
        const content: Content =
          new Content('',
            this.contentForm.value.title,
            this.contentForm.value.type || '',
            this.contentForm.value.imageType || 'above',
            this.contentForm.value.intro,
            this.contentForm.value.description,
            this.contentForm.value.image,
            this.contentForm.value.video,
            this.videoTime || 0,
            this.contentForm.value.quiz || '',
            this.contentForm.value.content,
            this.contentForm.value.order || 0,
            time || 0
          );

        this.eLearningService.addContent(this.trainingId, this.chapterId, content).then(r => {
          if (this.contentForm.value.type === 'quiz') {
            this.router.navigate(['/e-learning/training', this.trainingId, 'chapter', this.chapterId, 'content', r.id, 'quiz']).then();
          } else {
            this.router.navigate(['/e-learning/training', this.trainingId, 'chapter', this.chapterId, 'content', r.id]).then();
          }
        });
      } else {
        const content: Content =
          new Content(this.contentId,
            this.contentForm.value.title,
            this.contentForm.value.type || '',
            this.contentForm.value.imageType || 'above',
            this.contentForm.value.intro,
            this.contentForm.value.description,
            this.contentForm.value.image,
            this.contentForm.value.video,
            this.videoTime || 0,
            this.contentForm.value.quiz || '',
            this.contentForm.value.content,
            this.contentForm.value.order || 0,
            time || 0
          );
        this.eLearningService.updateContent(this.trainingId, this.chapterId, content).then(() => {
          this.router.navigate(['/e-learning/training', this.trainingId, 'chapter']).then();
        });
      }
    }
  }

  goToQuiz() {
    this.router.navigate(['/e-learning/training', this.trainingId, 'chapter', this.chapterId, 'content', this.contentId, 'quiz']).then();
  }

}
