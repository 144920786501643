export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBCQsqT_hU_y1UfUpEIrw2QNGRyFUeikC8",
    authDomain: "sbs-app-staging.firebaseapp.com",
    databaseURL: "https://sbs-app-staging.firebaseio.com",
    projectId: "sbs-app-staging",
    storageBucket: "sbs-app-staging.appspot.com",
    messagingSenderId: "190645810172",
    appId: "1:190645810172:web:01e3d0e3a0d5788b423879",
    measurementId: "G-7WRHX219YJ"
  },
  functionsUrl: 'https://europe-west3-sbs-app-staging.cloudfunctions.net',
  // functionsUrl: 'http://127.0.0.1:5001/sbs-app-staging/europe-west3'
};
