<h1>
  <mat-icon class="cursor-pointer" routerLink="/e-learning">arrow_back</mat-icon>
  {{'e-learning_admin_chapters' | translate}}
</h1>

<div class="grid gap-4 grid-cols-1">
  <div>
    <button mat-raised-button (click)="addChapter()">
      {{'e-learning_admin_chapter_add' | translate}}
    </button>
  </div>
  <div>
    <mat-accordion cdkDropList (cdkDropListDropped)="drop($event)" >
      <mat-expansion-panel *ngFor="let chapter of chapters" cdkDrag>
        <mat-action-row>
          <button mat-raised-button color="warn" (click)="deleteChapter($event, chapter.id!)">{{'e-learning_admin_chapter_delete' | translate}}</button>
          <button mat-button color="primary" (click)="goToChapter($event, chapter.id!)">{{'e-learning_admin_chapter_update' | translate}}</button>
        </mat-action-row>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{chapter.order}}: {{chapter.title | ct}} <mat-icon *ngIf="chapter.exam">workspace_premium</mat-icon>
          </mat-panel-title>
          <mat-panel-description>
            {{chapter.intro | ct}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>{{chapter.description | ct}}</p>
        <app-content
          [trainingId]="trainingId"
          [chapterId]="chapter.id!"
        ></app-content>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div>
    <button mat-raised-button (click)="saveSort()">
      {{'e-learning_admin_chapter_save_sorting' | translate}}
    </button>
  </div>
  <div>
    <button mat-raised-button (click)="addCertificate()">
      {{'e-learning_training_admin_certificate_admin' | translate}}
    </button>
  </div>
</div>
