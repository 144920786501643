import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from "@angular/forms";

@Directive({
  selector: '[appRequired]',
  providers: [{provide: NG_VALIDATORS, useExisting: RequiredDirective, multi: true}]
})
export class RequiredDirective implements Validator {
  @Input('appRequired') required = '';

  validate(control: AbstractControl): ValidationErrors | null {
    return requiredValidator()(control);
  }

}

export function requiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const invalid = !control.value || !control.value.de;
    return invalid ? {required: {valid: false}} : null;
  };
}
