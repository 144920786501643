<h1>
  <mat-icon class="cursor-pointer" routerLink="/e-learning/training/{{trainingId}}/chapter">arrow_back</mat-icon>
  {{(chapterId === 'create' ? 'e-learning_admin_chapter_add' : 'e-learning_admin_chapter_update') | translate}}
</h1>
<div class="w-full training-form">
  <form [formGroup]="chapterForm" (ngSubmit)="saveChapter()">
    <div>
      <mat-checkbox
        formControlName="exam">
        {{'e-learning_admin_exam' | translate}}
      </mat-checkbox>
    </div>
    <div *ngIf="chapterForm.controls.exam">
      <mat-form-field>
        <mat-label>{{'e-learning_admin_exam_min' | translate}}</mat-label>
        <input matInput formControlName="examMin" type="number">
      </mat-form-field>
    </div>
    <app-input-form label="e-learning_chapter_name" formControlName="title"/>
    <mat-form-field>
      <mat-label>{{'e-learning_training_target_groups' | translate}}</mat-label>
      <mat-select formControlName="targetGroups">
        <mat-option *ngFor="let tg of targetGroups" [value]="tg.id">
          {{tg.name | ct}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-input-form
      label="e-learning_chapter_intro"
      formControlName="intro"
      [required]="true"
    />
    <app-input-form [rows]="10" label="e-learning_chapter_description" formControlName="description"/>
    <app-input-form [image]="true" label="e-learning_chapter_image" formControlName="image"/>

    <div class="flex justify-end">
      <button mat-raised-button color="primary" type="submit" [disabled]="!chapterForm.valid">
        {{'e-learning_chapter_save' | translate}}</button>
    </div>
  </form>
</div>
