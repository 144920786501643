import { Injectable } from '@angular/core';
import {collection, doc, getDoc, getDocs, getFirestore, updateDoc} from "@angular/fire/firestore";

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  db = getFirestore();

  constructor() { }

  getTranslation() {
    const ref = doc(this.db, "translations", "de");
    return getDoc(ref);
  }

  updateTranslation(key: string, value: string) {
    const ref = doc(this.db, "translations", "de");
    return updateDoc(ref, {
      [key]: value
    });
  }
}
