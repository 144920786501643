<h1>
  <mat-icon class="cursor-pointer" routerLink="/e-learning">arrow_back</mat-icon>
  {{(id === 'create' ? 'e-learning_admin_create' : 'e-learning_admin_update') | translate}}
</h1>
<div class="w-full training-form">
  <form [formGroup]="trainingForm" (ngSubmit)="saveTraining()">
    <app-input-form label="e-learning_training_name" formControlName="title" [required]="true"/>
    <mat-checkbox
      formControlName="published">
      {{'e-learning_admin_published' | translate}}
    </mat-checkbox>
    <mat-form-field>
      <mat-label>{{'e-learning_training_price' | translate}}</mat-label>
      <input matInput formControlName="price" type="number" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'e-learning_training_category' | translate}}</mat-label>
      <mat-select formControlName="categories">
        <mat-option *ngFor="let cat of trainingCategories" [value]="cat.id">
          {{cat.name | ct}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'e-learning_training_target_groups' | translate}}</mat-label>
      <mat-select formControlName="targetGroups">
        <mat-option *ngFor="let tg of targetGroups" [value]="tg.id">
          {{tg.name | ct}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-input-form
      label="e-learning_training_intro"
      formControlName="intro"
      [required]="true"
    />
    <app-input-form [rows]="10" label="e-learning_training_description" formControlName="description" [required]="true"/>
    <app-input-form [image]="true" label="e-learning_training_image" formControlName="image"/>

    <div class="flex justify-between">
      <button *ngIf="id !== 'create'" mat-raised-button type="button"
              (click)="syncProduct()"
              [disabled]="!trainingForm.valid || loading ||
               (trainingForm.controls.price.value && trainingForm.controls.price.value <= 0)">
        <div class="flex flex-row gap-4">
          <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner> {{'e-learning_admin_training_sync_product' | translate}}
        </div>
      </button>
      <button mat-raised-button color="primary" type="submit" [disabled]="!trainingForm.valid || loading">
        <div class="flex flex-row gap-4">
          <mat-spinner class="spinner-white" *ngIf="loading" color="primary" [diameter]="20"></mat-spinner> {{'e-learning_admin_training_save' | translate}}
        </div>
      </button>
    </div>
  </form>
</div>
