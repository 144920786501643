import { Injectable } from '@angular/core';
import {collection, doc, Firestore, getDoc, getDocs} from '@angular/fire/firestore';
import {TranslateLoader} from "@ngx-translate/core";
import {Observable} from "rxjs/internal/Observable";


@Injectable({
  providedIn: 'root'
})
export class LanguageService implements TranslateLoader {
  translationsRef = collection(this.firestore, 'translations');
  translations: any[] = [];
  languages: string[] = [];

  constructor(
    private firestore: Firestore
  ) { }

  getTranslation(lang: string): Observable<any> {
    return Observable.create((observer: any) => {
      const document = doc(this.translationsRef, lang);
      getDoc(document).then((snap) => {
        if (snap.exists()) {
          console.log('get language ' + lang + ' from server');
          observer.next(snap.data());
          observer.complete();
        }
      }).catch(
        error => {
          console.log('get language ' + lang + ' from client; server error', error);
        }
      );
    });
  }

  getAllTranslation(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      if (this.languages.length === 0) {
        getDocs(this.translationsRef).then((snaps) => {
          this.translations = [];
          this.languages = [];
          snaps.forEach((snap) => {
            this.translations.push(snap.data());
            this.languages.push(snap.id);
          });
          resolve(this.languages);
        }).catch(
          error => {
            console.log('error getting translations from server', error);
            reject(error);
          }
        );
      } else {
        resolve(this.languages);
      }
    });
  }

  prepareLanguageObject() {
    const object: any = {};
    this.languages.forEach((lang) => {
      object[lang] = '';
    });
    return object;
  }
}
