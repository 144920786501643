<div class="flex items-baseline sm:items-center justify-center h-screen bg-white sm:bg-gray-100">
  <div class="p-8 bg-white rounded">
    <h1 *ngIf="!env.production">staging</h1>

    <img style="max-width: 500px; width: 100%; padding-bottom: 50px;" src="/assets/images/logo.png">

    <h1 id="title" class="title">Anmelden</h1>
    <form [formGroup]="loginForm" (ngSubmit)="signIn()">
      <mat-form-field class="w-full">
        <mat-label>E-Mail</mat-label>
        <input nbInput
               matInput
               formControlName="mail"
               name="email"
               id="input-email"
               pattern=".+@.+\..+"
               placeholder="E-Mail-Adresse"
               required
               autofocus>
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Passwort</mat-label>
        <input nbInput
               matInput
               formControlName="password"
               name="password"
               type="password"
               id="input-password"
               required
               placeholder="Passwort">
      </mat-form-field>

      <div *ngIf="loginError" class="mb-6">
        <span class="text-red-600">{{errorMessage}}</span>
      </div>

      <button mat-raised-button type="submit" color="primary">
        Anmelden
      </button>

      <div class="mt-8 cursor-pointer">
        <a routerLink="../request-password">Passwort vergessen?</a>
      </div>
      <br />
      <br />
      <br />
      <br />
      Noch kein Zugriff? Dann wenden Sie sich an <a href="mailto:post@sbs-info.de">post@sbs-info.de</a>.
    </form>
  </div>
</div>
