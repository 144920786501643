import {Component, inject, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {CellClickedEvent, ColDef, GridReadyEvent} from "ag-grid-community";
import {AgGridAngular} from "ag-grid-angular";
import {TranslationService} from "../../services/translation.service";

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent {

  private translationService: TranslationService = inject(TranslationService);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private translate: TranslateService = inject(TranslateService);

  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    {
      field: 'key',
      width: 400,
      suppressSizeToFit: true,
      sort: 'asc'
    },
    {
      field: 'value',
      editable: true,
      cellStyle: {'white-space': 'normal'},
      autoHeight: true,
    }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  public rowData$!: any[];

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  // Example load data from server
  onGridReady(params: GridReadyEvent) {
    this.translationService.getTranslation().then((translationSnap) => {
      const translation: any = translationSnap.data();
      // convert all attributes to key value list
      this.rowData$ = Object.keys(translation).map((key) => {
        return {
          key: key,
          value: translation[key]
        };
      });
    });
  }

  onFirstDataRendered(params: GridReadyEvent) {
    params.api.sizeColumnsToFit();
  }

  // Example of consuming Grid Event
  onCellClicked( e: CellClickedEvent): void {
    console.log('cellClicked', e);
  }

  // Example of consuming Grid Event
  onCellEditingStopped( e: CellClickedEvent): void {
    console.log('onCellEditingStopped', e);
    this.translationService.updateTranslation(e.data.key, e.data.value).then(() => {
      this.openSnackBar(this.translate.instant('admin_translation_saved'));
    });
  }

  // Example using Grid's API
  clearSelection(): void {
    this.agGrid.api.deselectAll();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 15 * 1000,
    });
  }

}
