import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Training} from "../../../../../models/training.model";
import {ConfirmDialogComponent} from "../../../../../common/confirm-dialog/confirm-dialog.component";
import {ELearningService} from "../../../../../services/e-learning.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-single-training',
  templateUrl: './single-training.component.html',
  styleUrls: ['./single-training.component.scss']
})
export class SingleTrainingComponent {

  @Input() createNew: boolean = false;
  @Input() training: Training | null = null;

  @Output() trainingDelete = new EventEmitter<Training>();

  constructor(
    private eLearningService: ELearningService,
    private dialog: MatDialog,
  ) {
  }

  deleteTraining(training: Training) {
    this.openConfirmDeleteDialog(training);
  }

  openConfirmDeleteDialog(training: Training): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: 'e-learning_admin_confirm_delete',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if (result && result.confirmed) {
        this.eLearningService.deleteTraining(training.id!).then(() => {
          this.trainingDelete.emit();
        });
      }
    });
  }

}
