import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore, orderBy,
  query,
  updateDoc
} from "@angular/fire/firestore";
import {Training, trainingConverter} from "../models/training.model";
import {Simple, simpleConverter} from "../models/simple.model";
import {Chapter, chapterConverter} from "../models/chapter.model";
import {Content, contentConverter} from "../models/content.model";
import {Quiz, quizConverter} from "../models/quiz.model";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class ELearningService {

  db = getFirestore();
  trainingRef = collection(this.db, "trainings");
  trainingCategoriesRef = collection(this.db, "trainingCategories");
  targetGroupsRef = collection(this.db, "trainingTargetGroups");

  url = environment.functionsUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getTrainingCategories() {
    return getDocs(this.trainingCategoriesRef.withConverter(simpleConverter));
  }

  addTrainingCategory(data: any) {
    const cat = {
      name: data.name,
      created: new Date(),
      updated: new Date(),
    }
    return addDoc(this.trainingCategoriesRef, cat);
  }

  updateTrainingCategory(data: Simple) {
    const cat = {
      name: data.name,
      updated: new Date(),
    }
    const ref = doc(this.db, "trainingCategories", data.id!);
    return updateDoc(ref, cat);
  }

  deleteTrainingCategory(id: string) {
    const ref = doc(this.db, "trainingCategories", id);
    return deleteDoc(ref);
  }

  getTargetGroups() {
    return getDocs(this.targetGroupsRef.withConverter(simpleConverter));
  }

  addTargetGroup(data: any) {
    const cat = {
      name: data.name,
      created: new Date(),
      updated: new Date(),
    }
    return addDoc(this.targetGroupsRef, cat);
  }

  updateTargetGroup(data: Simple) {
    const cat = {
      name: data.name,
      updated: new Date(),
    }
    const ref = doc(this.db, "trainingTargetGroups", data.id!);
    return updateDoc(ref, cat);
  }

  deleteTargetGroup(id: string) {
    const ref = doc(this.db, "trainingTargetGroups", id);
    return deleteDoc(ref);
  }

  getTrainings() {
    return getDocs(this.trainingRef.withConverter(trainingConverter));
  }

  getTraining(id: string) {
    const ref = doc(this.db, "trainings", id);
    return getDoc(ref.withConverter(trainingConverter));
  }

  addTraining(data: Training) {
    data.created = new Date();
    data.updated = new Date();
    return addDoc(this.trainingRef.withConverter(trainingConverter), data);
  }

  updateTraining(data: Training) {
    const training: any = JSON.parse(JSON.stringify(data));
    training.updated = new Date();
    delete training.created;
    delete training.id;
    const ref = doc(this.db, "trainings", data.id!);
    return updateDoc(ref, training);
  }

  setCertificateForTraining(trainingId: string, certificatePath: string) {
    const data = {
      updated: new Date(),
      certificatePath: certificatePath
    }
    const ref = doc(this.db, "trainings", trainingId);
    return updateDoc(ref, data);
  }

  setBadgeForTraining(trainingId: string, badgeUrl: string) {
    const data = {
      updated: new Date(),
      badgeUrl: badgeUrl
    }
    const ref = doc(this.db, "trainings", trainingId);
    return updateDoc(ref, data);
  }

  deleteTraining(id: string) {
    const ref = doc(this.db, "trainings", id);
    return deleteDoc(ref);
  }

  getChapters(trainingId: string) {
    const chapterRef = collection(this.db, "trainings", trainingId, "chapters");
    const q = query(chapterRef.withConverter(chapterConverter), orderBy("order"));
    return getDocs(q);
  }

  getChapter(trainingId: string, chapterId: string) {
    const ref = doc(this.db, "trainings", trainingId, "chapters", chapterId);
    return getDoc(ref.withConverter(chapterConverter));
  }

  async addChapter(trainingId: string, data: Chapter) {
    data.created = new Date();
    data.updated = new Date();
    const ref = collection(this.db, "trainings", trainingId, "chapters");
    const snapshot = await getCountFromServer(ref);
    data.order = snapshot.data().count + 1;
    return addDoc(ref.withConverter(chapterConverter), data);
  }

  updateChapter(trainingId: string, data: Chapter) {
    const chapter: any = JSON.parse(JSON.stringify(data));
    chapter.updated = new Date();
    delete chapter.created;
    delete chapter.id;
    const ref = doc(this.db, "trainings", trainingId, "chapters", data.id!);
    return updateDoc(ref, chapter);
  }

  updateChapterOrder(trainingId: string, data: any) {
    const toUpdate = {
      order: data.order,
    }
    const ref = doc(this.db, "trainings", trainingId, "chapters", data.id!);
    return updateDoc(ref, toUpdate);
  }

  deleteChapter(trainingId: string, chapterId: string) {
    const ref = doc(this.db, "trainings", trainingId, "chapters", chapterId);
    return deleteDoc(ref);
  }

  getContents(trainingId: string, chapterId: string) {
    const contentRef = collection(this.db, "trainings", trainingId, "chapters", chapterId, "contents");
    const q = query(contentRef.withConverter(contentConverter), orderBy("order"));
    return getDocs(q);
  }

  getContent(trainingId: string, chapterId: string, contentId: string) {
    const ref = doc(this.db, "trainings", trainingId, "chapters", chapterId, "contents", contentId);
    return getDoc(ref.withConverter(contentConverter));
  }

  async addContent(trainingId: string, chapterId: string, data: Content) {
    data.created = new Date();
    data.updated = new Date();
    const ref = collection(this.db, "trainings", trainingId, "chapters", chapterId, "contents");
    const snapshot = await getCountFromServer(ref);
    data.order = snapshot.data().count + 1;
    return addDoc(ref.withConverter(contentConverter), data);
  }

  updateContent(trainingId: string, chapterId: string, data: Content) {
    const content: any = JSON.parse(JSON.stringify(data));
    content.updated = new Date();
    delete content.created;
    delete content.id;
    const ref = doc(this.db, "trainings", trainingId, "chapters", chapterId, "contents", data.id!);
    return updateDoc(ref, content);
  }

  updateContentOrder(trainingId: string, chapterId: string, data: any) {
    const toUpdate = {
      order: data.order,
    }
    const ref = doc(this.db, "trainings", trainingId, "chapters", chapterId, "contents", data.id!);
    return updateDoc(ref, toUpdate);
  }

  deleteContent(trainingId: string, chapterId: string, contentId: string) {
    const ref = doc(this.db, "trainings", trainingId, "chapters", chapterId, "contents", contentId);
    return deleteDoc(ref);
  }

  getQuizzes(trainingId: string, chapterId: string, contentId: string) {
    const contentRef = collection(this.db, "trainings", trainingId, "chapters", chapterId, "contents", contentId, "quizzes");
    return getDocs(contentRef.withConverter(quizConverter));
  }

  getQuiz(trainingId: string, chapterId: string, contentId: string, quizId: string) {
    const ref = doc(this.db, "trainings", trainingId, "chapters", chapterId, "contents", contentId, "quizzes", quizId);
    return getDoc(ref.withConverter(quizConverter));
  }

  addQuiz(trainingId: string, chapterId: string, contentId: string, data: Quiz) {
    data.created = new Date();
    data.updated = new Date();
    const ref = collection(this.db, "trainings", trainingId, "chapters", chapterId, "contents", contentId, "quizzes");
    return addDoc(ref.withConverter(quizConverter), data);
  }

  updateQuiz(trainingId: string, chapterId: string, contentId: string, data: Quiz) {
    const quiz: any = JSON.parse(JSON.stringify(data));
    quiz.updated = new Date();
    delete quiz.created;
    delete quiz.id;
    const ref = doc(this.db, "trainings", trainingId, "chapters", chapterId, "contents", contentId, "quizzes", data.id!);
    return updateDoc(ref, quiz);
  }

  deleteQuiz(trainingId: string, chapterId: string, contentId: string, quizId: string) {
    const ref = doc(this.db, "trainings", trainingId, "chapters", chapterId, "contents", contentId, "quizzes", quizId);
    return deleteDoc(ref);
  }

  syncProduct(trainingId: string) {
    return new Promise((resolve, reject) => {
      this.authService.getToken().subscribe((token) => {
        this.http.get<any>(`${this.url}/upsertProduct?trainingId=${trainingId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).subscribe({
          next: (productResponse)=> {
            resolve(productResponse);
          }, error: (error) => {
            reject(error);
          }
        });
      });
    });
  }

}
