import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationComponent } from './translation.component';
import {AgGridModule} from "ag-grid-angular";



@NgModule({
  declarations: [
    TranslationComponent
  ],
  imports: [
    CommonModule,
    AgGridModule
  ]
})
export class TranslationModule { }
