<div [class.group-error]="hasError">
  <mat-tab-group [selectedIndex]="2" mat-stretch-tabs="false" mat-align-tabs="start" #tabGroup>
    <mat-tab *ngFor="let lang of languages" [label]="lang">
      <div *ngIf="image">
        <app-upload-image [referenceId]="referenceId" [reference]="reference" (receivedDownloadUrl)="onReceiveDownloadUrl($event, lang)" label="e-learning_training_image"></app-upload-image>
      </div>
      <div *ngIf="video">
        <app-upload-video
          (receivedVideoDuration)="onReceiveVideoDuration($event, lang)"
          (receivedDownloadUrl)="onReceiveDownloadUrl($event, lang)" label="e-learning_training_video"></app-upload-video>
      </div>
      <mat-form-field *ngIf="!editor">
        <mat-label>{{label | translate}}</mat-label>
        <input *ngIf="rows === 0"
               [required]="required"
               matInput [value]="value[lang] || ''"
               (change)="onChangeInputValue($event, lang)">
        <textarea *ngIf="rows > 0"
                  [required]="required"
                  [rows]="rows" matInput
                  [value]="value[lang] || ''"
                  (change)="onChangeInputValue($event, lang)"></textarea>
      </mat-form-field>
      <div *ngIf="editor">
        <quill-editor
          [ngModel]="value[lang]"
          (ngModelChange)="onChangeEditorValue($event, lang)"
          [placeholder]="label | translate"
          [styles]="{height: '250px'}">
        </quill-editor>
      </div>
    </mat-tab>
  </mat-tab-group>
  <mat-error *ngIf="hasError" class="form-alert">
    {{ 'required_missing' | translate}}
  </mat-error>
</div>
