<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-nav-list>
      <img alt="SBS logo" class="p-2" style="max-width: 500px; width: 100%; padding-bottom: 50px;" src="/assets/images/logo.png">
      <a *ngIf="isGlobalAdmin" mat-list-item href="./user">{{'admin_user' | translate}}</a>
      <a *ngIf="isGlobalAdmin" mat-list-item href="./translation">{{'admin_translation' | translate}}</a>
      <a *ngIf="isTachographAdmin" mat-list-item href="./device/media">{{'tachograph' | translate}}</a>
      <a *ngIf="isElearningAdmin" mat-list-item href="./e-learning">{{'e-learning' | translate}}</a>
      <a *ngIf="isElearningAdmin" mat-list-item href="./e-learning-reports">{{'e-learning-reports' | translate}}</a>

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="flex z-100">
      <button
        type="button"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon class="!align-baseline" >menu</mat-icon>
      </button>
      <span class="grow">SBS Admin V2</span>
      <button
        type="button"
        mat-icon-button
        (click)="signOut()">
        <mat-icon class="!align-baseline">logout</mat-icon>
      </button>
    </mat-toolbar>
    <div class="p-2">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
