<h1>
  <mat-icon class="cursor-pointer" routerLink="/e-learning/training/{{trainingId}}/chapter/{{chapterId}}/content/{{contentId}}/quiz">arrow_back</mat-icon>
  {{contentId === 'create' ? 'e-learning_admin_quiz_add' : 'e-learning_admin_quiz_update' | translate}}
</h1>
<div class="w-full quiz-form">
  <form [formGroup]="quizForm" (ngSubmit)="saveQuiz()">
    <app-input-form
      label="e-learning_quiz_name"
      formControlName="title"
      [required]="true"
    />
    <mat-form-field>
      <mat-label>{{'e-learning_quiz_type' | translate}}</mat-label>
      <mat-select formControlName="type" (selectionChange)="onTypeChange()">
        <mat-option *ngFor="let type of types" [value]="type">
          {{'e-learning_quiz_type_' + type | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="quizForm.controls.type.value">
      <app-input-form [image]="true" label="e-learning_quiz_image" formControlName="image"/>
      <app-input-form [video]="true" label="e-learning_quiz_video" formControlName="video"/>
      <app-input-form
        label="e-learning_quiz_question"
        formControlName="question"
        [required]="true"
      />
      <app-input-form
        label="e-learning_quiz_explanation"
        formControlName="explanation"
      />
      <app-input-form *ngIf="quizForm.controls.type.value == 'video'" label="e-learning_quiz_video" formControlName="video"/>
      <div *ngIf="['single', 'multi', 'drag', 'fill'].includes(quizForm.controls.type.value!)">
        <button type="button" class="m-5" mat-button color="primary" (click)="addOption()">
          {{ 'e-learning_admin_quiz_option_add' | translate }}
        </button>

        <table mat-table [dataSource]="selectDataSource" class="mat-elevation-z1 mb-5">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? toggleAllMultiRows() : null"
                            [checked]="selection.hasValue() && isAllMultiSelected()"
                            [indeterminate]="selection.hasValue() && !isAllMultiSelected()">
              </mat-checkbox>
              {{ 'learning_quiz_option_correct' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="question">
            <th mat-header-cell *matHeaderCellDef>
              {{ quizForm.controls.type.value == 'fill' ? 'e-learning_quiz_option_text' : 'e-learning_quiz_option_left' | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
              <div [innerHTML]="element.question | ct : element.answer"></div>
            </td>
          </ng-container>

          <ng-container matColumnDef="answer">
            <th mat-header-cell *matHeaderCellDef>
              {{(quizForm.controls.type.value == 'drag' ?
              'e-learning_quiz_option_right' :
              (quizForm.controls.type.value == 'fill' ? 'e-learning_quiz_option_fields' : 'e-learning_quiz_option_answer')) | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.answer | ct}}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element" class="!text-right">
              <button mat-icon-button type="button" (click)="updateOption($event, element)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button type="button" (click)="deleteOption($event, element.id!)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedSingleColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedSingleColumns;"></tr>
        </table>
        <div *ngIf="quizForm.controls.type.value == 'fill'">
          <mat-checkbox
            formControlName="useFillOptions">
            {{'e-learning_admin_quiz_fill_options' | translate}}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <mat-form-field *ngIf="quizForm.controls.type.value">
      <mat-label>{{'e-learning_quiz_points' | translate}}</mat-label>
      <input matInput type="number" placeholder="{{ 'e-learning_quiz_points' | translate }}" formControlName="points">
    </mat-form-field>
    <div class="flex justify-end">
      <button mat-raised-button color="primary" type="submit" [disabled]="!quizForm.valid">
        {{'e-learning_quiz_save' | translate}}</button>
    </div>
  </form>
</div>
