export class User {
  id?: string;
  appKey?: string;
  deviceId?: string;
  email: string;
  firstName?: string;
  group?: string;
  lastName?: string;
  nfcRegistration: boolean;
  nfcTag?: string;
  paidUser: boolean;
  storeRegistration: boolean;
  role?: "groupAdmin" | "globalAdmin";
  hasTachographPermission: boolean;
  hasELearningPermission: boolean;
  updated?: Date;
  created?: Date;

  constructor(id: string, appKey: string, deviceId: string, email: string, firstName: string, group: string, lastName: string, nfcRegistration: boolean, nfcTag: string, paidUser: boolean, storeRegistration: boolean, role: "groupAdmin" | "globalAdmin", hasTachographPermission: boolean, hasELearningPermission: boolean, updated?: Date, created?: Date) {
    this.id = id;
    this.appKey = appKey || "";
    this.deviceId = deviceId || "";
    this.email = email;
    this.firstName = firstName || "";
    this.group = group || "";
    this.lastName = lastName || "";
    this.nfcRegistration = nfcRegistration || false;
    this.nfcTag = nfcTag || "";
    this.paidUser = paidUser || false;
    this.storeRegistration = storeRegistration || false;
    this.role = role || "";
    this.hasTachographPermission = hasTachographPermission;
    this.hasELearningPermission = hasELearningPermission;
    this.updated = updated;
    this.created = created;
  }
}

export const userConverter = {
  toFirestore: (user: User) => {
    const permissions: string[] = [];
    if (user.hasTachographPermission) {
      permissions.push("admin_tachograph");
    }
    if (user.hasELearningPermission) {
      permissions.push("admin_e-learning");
    }
    return {
      appKey: user.appKey,
      deviceId: user.deviceId,
      email: user.email,
      firstName: user.firstName,
      group: user.group,
      lastName: user.lastName,
      nfcRegistration: user.nfcRegistration,
      nfcTag: user.nfcTag,
      paidUser: user.paidUser,
      storeRegistration: user.storeRegistration,
      role: user.role || "",
      permissions: permissions || [],
      created: user.created || new Date(),
      updated: user.updated || new Date(),
    }
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    const hasTachographPermission = data.permissions ? data.permissions.includes("admin_tachograph") : false;
    const hasELearningPermission = data.permissions ? data.permissions.includes("admin_e-learning") : false;
    return new User(snapshot.id, data.appKey, data.deviceId, data.email, data.firstName, data.group, data.lastName, data.nfcRegistration, data.nfcTag, data.paidUser, data.storeRegistration, data.role, hasTachographPermission, hasELearningPermission, data.updated, data.created);
  }
};
