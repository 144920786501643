import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Simple} from "../../models/simple.model";

@Component({
  selector: 'app-simple-form-dialog',
  templateUrl: './simple-form-dialog.component.html',
  styleUrls: ['./simple-form-dialog.component.scss']
})
export class SimpleFormDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SimpleFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      data: Simple
    },
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }
}
