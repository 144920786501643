<!-- Button to clear selection -->
<button (click)="clearSelection()">Clear Selection</button>
<button mat-raised-button (click)="addUser()">{{'add_user' | translate}}</button>
<!-- AG Grid Angular Component -->
<ag-grid-angular
  class="ag-theme-alpine w-full h-[850px]"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [rowData]="rowData$"
  [rowSelection]="'multiple'"
  [animateRows]="true"
  [enableCellTextSelection]="true"
  (gridReady)="onGridReady($event)"
  (cellClicked)="onCellClicked($event)"
  (cellEditingStopped)="onCellEditingStopped($event)"
></ag-grid-angular>
