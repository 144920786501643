import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FileUpload} from "../../../models/file-upload.model";
import {finalize} from "rxjs/operators";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {ActivatedRoute, Router} from "@angular/router";
import {ELearningService} from "../../../services/e-learning.service";
import {environment} from "../../../../environments/environment";
import {Training} from "../../../models/training.model";
@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})


export class CertificatesComponent implements OnDestroy{
  env = environment;
  functionsUrl = this.env.functionsUrl;
  private basePath = 'uploads';
  selectedCertFile?: File;
  currentCertFileUpload?: FileUpload;
  isCertFileUploaded = false;
  selectedBadgeFile?: File;
  currentBadgeFileUpload?: FileUpload;
  isBadgeFileUploaded = false;
  training: Training | null = null;
  certificateUrl?: string;
  badgeUrl?: string;
  private sub: any;
  @Input() image = false;
  @Output() pdfScreenshotEmitter = new EventEmitter<string | null>();

  reference = 'certificates';
  trainingId = '';


  constructor(
    private storage: AngularFireStorage,
    private router: Router,
    private route: ActivatedRoute,
    private eLearningService: ELearningService
  ) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.trainingId = params['id'] || '';
      this.getTraining();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getTraining() {
    this.eLearningService.getTraining(this.trainingId).then(trainingSnap => {
      if (trainingSnap.exists()) {
        this.training = trainingSnap.data();
        if (trainingSnap.data()?.certificatePath) {
          this.certificateUrl = this.functionsUrl + '/getFile?path=' + trainingSnap.data()?.certificatePath;
          this.badgeUrl = this.training.badgeUrl;
        }
      } else {
        this.router.navigate(['/e-learning']).then();
      }
    });
  }

  selectCertFile(event: any): void {
    this.selectedCertFile = event.target.files[0];
    this.isCertFileUploaded = false;
  }

  selectBadgeFile(event: any): void {
    this.selectedBadgeFile = event.target.files[0];
    this.isBadgeFileUploaded = false;
  }

  uploadCertFile(): void {
    if (this.selectedCertFile) {
      // Ensure the file has the .pdf extension
      const fileExtension = '.pdf';
      const fileName = this.trainingId ? this.trainingId + fileExtension : this.selectedCertFile.name;
      this.currentCertFileUpload = new FileUpload('', fileName, '', this.selectedCertFile, this.reference);
      const filePath = `${this.basePath}/${this.currentCertFileUpload.reference}/${fileName}`;
      const uploadTask = this.storage.upload(filePath, this.currentCertFileUpload.file);

      uploadTask.snapshotChanges().pipe(
        finalize(() => {
          this.eLearningService.setCertificateForTraining(this.trainingId, filePath).then(() => {});
          // storageRef.getDownloadURL().subscribe((downloadURL: string) => {
            // console.log('File uploaded:', downloadURL);
            this.isCertFileUploaded = true;
            this.selectedCertFile = undefined;
          // });
        })
      ).subscribe();
    }
  }

  uploadBadgeFile(): void {
    if (this.selectedBadgeFile) {
      // Ensure the file has the .pdf extension
      const fileExtension = this.selectedBadgeFile.name.split('.').pop();
      const fileName = this.trainingId ? this.trainingId + fileExtension : this.selectedBadgeFile.name;
      this.currentBadgeFileUpload = new FileUpload('', fileName, '', this.selectedBadgeFile, this.reference);
      const filePath = `${this.basePath}/${this.currentBadgeFileUpload.reference}/${fileName}`;
      const storageRef = this.storage.ref(filePath);
      const uploadTask = this.storage.upload(filePath, this.currentBadgeFileUpload.file);

      uploadTask.snapshotChanges().pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe((badgeUrl: string) => {
            this.eLearningService.setBadgeForTraining(this.trainingId, badgeUrl).then(() => {
              this.isBadgeFileUploaded = true;
              this.selectedBadgeFile = undefined;
              this.badgeUrl = badgeUrl;
            });
          });
        })
      ).subscribe();
    }
  }

  cancelCert(): void {
    this.selectedCertFile = undefined;
  }

  cancelBadge(): void {
    this.selectedBadgeFile = undefined;
  }

}
