<table>
  <thead>
  <tr>
    <th>Groupe</th>
    <th>First Name</th>
    <th>Last Name</th>
    <th>Training ID</th>
    <th>Started At</th>
    <th>Completed Percents</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of combinedData$ | async">
    <td>{{ data.groupe }}</td>
    <td>{{ data.firstName }}</td>
    <td>{{ data.lastName }}</td>
    <td>{{ data.trainingId ? data.trainingId : 'N/A' }}</td>
    <td>{{ data.startedAt ? data.startedAt : 'N/A' }}</td>
    <td>{{ data.completedPercents ? data.completedPercents : 'N/A' }}</td>
  </tr>
  </tbody>
</table>
