<h1>
  <mat-icon class="cursor-pointer" routerLink="/e-learning/training/{{trainingId}}/chapter">arrow_back</mat-icon>
  {{'e-learning_training_certificate_for' | translate}} {{training?.title | ct}}
</h1>
<div class="flex flex-col">
  <h2>{{'e-learning_training_admin_certificate_add' | translate}}</h2>
  <input type="file" accept=".pdf" (change)="selectCertFile($event)">
  <div *ngIf="selectedCertFile" class="flex">
    <button mat-raised-button color="primary" (click)="uploadCertFile()">{{'upload' | translate}}</button>
    <button mat-raised-button color="warn" >{{'cancel' | translate}}</button>
  </div>
  <div *ngIf="certificateUrl">
    <pdf-viewer [src]="certificateUrl"
                [render-text]="true"
                [original-size]="false"
                [autoresize]="true"
                [fit-to-page]="true"
                style="width: 370px; height: 500px"
    ></pdf-viewer>
  </div>
  <br />
  <h2>{{'e-learning_training_admin_badge_add' | translate}}</h2>
  <input type="file" accept="image/*" (change)="selectBadgeFile($event)">
  <div *ngIf="selectedBadgeFile" class="flex">
    <button mat-raised-button color="primary" (click)="uploadBadgeFile()">{{'upload' | translate}}</button>
    <button mat-raised-button color="warn" >{{'cancel' | translate}}</button>
  </div>
  <div *ngIf="badgeUrl">
    <img [src]="badgeUrl" class="w-48 mt-4" />
  </div>
</div>
