import {Component, OnInit} from '@angular/core';
import {ELearningService} from "../../services/e-learning.service";
import {Training} from "../../models/training.model";

@Component({
  selector: 'app-e-learning',
  templateUrl: './e-learning.component.html',
  styleUrls: ['./e-learning.component.scss']
})
export class ELearningComponent implements OnInit {

  trainings: Training[] = [];

  constructor(
    private eLearningService: ELearningService,
  ) {
  }

  ngOnInit(): void {
    this.getTrainings();
  }

  onTrainingDelete() {
    this.getTrainings();
  }

  getTrainings() {
    this.eLearningService.getTrainings().then(trainingsSnap => {
      const trainings: Training[] = [];
      trainingsSnap.docs.map(trainingSnap => {
        trainings.push(trainingSnap.data());
      });
      this.trainings = trainings;
    }).catch(error => {
      console.error(error);
    });
  }

}
