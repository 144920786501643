import { Component } from '@angular/core';
import {StorageService} from "../../../services/storage.service";
import {FileUpload} from "../../../models/file-upload.model";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../common/confirm-dialog/confirm-dialog.component";
import {limit, onSnapshot, query, where} from "@angular/fire/firestore";
import {FlowService} from "../../../services/flow.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent {

  mediaFiles: any[] = [];
  flows: any[] = [];
  selectedFiles?: FileList;
  currentFileUpload?: FileUpload;
  percentage = 0;
  sub: any;
  flowSub: any;
  flowName: string = '';
  flowType: string = '';
  flowTypes: string[] = ['stoneridge', 'vdo'];

  constructor(
    private storageService: StorageService,
    private flowService: FlowService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getMedia();
    this.getFlows();
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub();
    }
    if (this.flowSub) {
    this.flowSub();
    }
  }

  getFlows(): any {
    const q = query(this.flowService.flowRef, limit(10000));

    this.flowSub = onSnapshot(q, (querySnapshot: any) => {
      const flows: any[] = [];
      querySnapshot.forEach((doc: any) => {
        const flow = doc.data();
        flow.id = doc.id;
        flows.push(flow);
      });
      this.flows = flows;
    });
  }

  getMedia(): any {
    const q = query(this.storageService.fileRef,
      where('reference', '==', 'tachograph'));
    this.sub = onSnapshot(q, (querySnapshot: any) => {
      const mediaFiles: any[] = [];
      querySnapshot.forEach((doc: any) => {
        const media = doc.data();
        media.id = doc.id;
        mediaFiles.push(media);
      });
      this.mediaFiles = mediaFiles;
    });
  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  uploadMedia(): any {
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      this.selectedFiles = undefined;

      if (file) {
        this.currentFileUpload = new FileUpload('', file.name, '', file, 'tachograph');
        this.storageService.pushFileToStorage(this.currentFileUpload).subscribe(
          percentage => {
            this.percentage = Math.round(percentage ? percentage : 0);
          },
          error => {
            console.log(error);
          }
        );
      }
    }
  }

  deleteMedia(file: FileUpload) {
    this.openConfirmDeleteDialog(file);
  }

  openConfirmDeleteDialog(file: FileUpload): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if (result && result.confirmed) {
        this.storageService.deleteFile(file).then();
      }
    });
  }

  createFlow(): any {
    this.flowService.saveFlowData(this.flowName, this.flowType).then((result) => {
      console.log(result);
      this.openFlowManager(result.id);
    });
  }

  openFlowManager(id: string) {
    window.open("https://sbs-flow.firebaseapp.com/?id=" + id, "_blank");
  }

  openContentEditor(id: string) {
    this.router.navigate(['/device', 'content', id]).then();
  }
}
