import { Injectable } from '@angular/core';
import {addDoc, collection, deleteDoc, doc, getDoc, getFirestore, setDoc} from "@angular/fire/firestore";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {FileUpload} from "../models/file-upload.model";
import {FlowContent, flowContentConverter} from "../models/flow-content.model";

@Injectable({
  providedIn: 'root'
})
export class FlowService {

  db = getFirestore();
  flowRef = collection(this.db, 'flow');

  constructor() { }

  getFlowData(id: string) {
    return getDoc(doc(this.flowRef, id));
  }

  saveFlowData(flowName: string, flowType: string) {
    return addDoc(this.flowRef, JSON.parse(JSON.stringify({
      name: flowName,
      type: flowType
    })));
  }

  getFlowContent(flowId: string, nodeId: string) {
    return getDoc(doc(collection(this.flowRef, flowId, 'content')
      .withConverter(flowContentConverter), nodeId));
  }

  saveFlowContent(flowId: string, nodeId: string, content: FlowContent) {
    return setDoc(doc(collection(this.flowRef, flowId, 'content')
      .withConverter(flowContentConverter), nodeId), content);
  }

}
