import { Injectable } from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public afAuth: AngularFireAuth,
  ) { }

  signIn(mail: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(mail, password);
  }

  signOut() {
    return this.afAuth.signOut();
  }

  isSignedIn() {
    return this.afAuth.authState;
  }

  getToken() {
    return this.afAuth.idToken;
  }
}
