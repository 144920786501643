import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {ContentTranslateModule} from "../../pipes/content-translate/content-translate.module";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule,
    ContentTranslateModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    ContentTranslateModule
  ]
})
export class SharedModule { }
