import { NgModule } from '@angular/core';
import { ELearningComponent } from './e-learning.component';
import { SingleTrainingComponent } from './trainings/components/single-training/single-training.component';
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import { TrainingFormComponent } from './trainings/training-form/training-form.component';
import {RouterModule} from "@angular/router";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import { SettingsComponent } from './settings/settings.component';
import {MatTableModule} from "@angular/material/table";
import {SharedModule} from "../../common/shared/shared.module";
import {InputFormModule} from "../../common/input-form/input-form.module";
import { ChaptersComponent } from './chapters/chapters.component';
import {MatExpansionModule} from "@angular/material/expansion";
import { ChapterFormComponent } from './chapters/chapter-form/chapter-form.component';
import { ContentComponent } from './content/content.component';
import { ContentFormComponent } from './content/content-form/content-form.component';
import {QuillEditorComponent} from "ngx-quill";
import { QuizComponent } from './quiz/quiz.component';
import { QuizFormComponent } from './quiz/quiz-form/quiz-form.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { CertificatesComponent } from './certificates/certificates.component';
import {PdfViewerModule} from "ng2-pdf-viewer";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
    ELearningComponent,
    SingleTrainingComponent,
    TrainingFormComponent,
    SettingsComponent,
    ChaptersComponent,
    ChapterFormComponent,
    ContentComponent,
    ContentFormComponent,
    QuizComponent,
    QuizFormComponent,
    CertificatesComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    InputFormModule,
    MatExpansionModule,
    QuillEditorComponent,
    DragDropModule,
    PdfViewerModule,
    MatProgressSpinnerModule
  ]
})
export class ELearningModule { }
