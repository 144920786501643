<div class="upload-container">
  <input id="fileInput" type="file" (change)="selectImage($event)" accept="image/*">
  <button id="uploadButton" mat-raised-button
          color="primary"
          class="mb-2"
          type="button"
          (click)="uploadImage()"
          *ngIf="selectedImage">{{'upload' | translate}}</button>
  <div *ngIf="percentage > 0">{{ percentage }}%</div>
</div>
