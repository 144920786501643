<h1>
  <mat-icon class="cursor-pointer" routerLink="/e-learning/training/{{trainingId}}/chapter">arrow_back</mat-icon>
  {{(contentId === 'create' ? 'e-learning_admin_content_add' : 'e-learning_admin_content_update') | translate}}
</h1>
<div *ngIf="contentId != 'create' && contentForm.controls.type.value == 'quiz'">
  <button mat-raised-button (click)="goToQuiz()">
    {{'e-learning_admin_quiz_admin' | translate}}
  </button>
</div>
<div class="w-full content-form">
  <form [formGroup]="contentForm" (ngSubmit)="saveContent()">
    <app-input-form label="e-learning_content_name" formControlName="title"/>
    <app-input-form
      label="e-learning_content_intro"
      formControlName="intro"
      [required]="true"
    />
    <app-input-form
      (valueChanged)="onDescriptionValueChange($event)"
      [rows]="10" label="e-learning_content_description" formControlName="description"/>
    <mat-form-field class="w-full">
      <mat-label>{{'e-learning_content_type' | translate}}</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of types" [value]="type">
          {{'e-learning_content_type_' + type | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-input-form *ngIf="contentForm.controls.type.value == 'text'"
                    (valueChanged)="onTextEditorValueChange($event)"
                    [editor]="true" label="e-learning_content_content" formControlName="content"/>
    <app-input-form *ngIf="contentForm.controls.type.value == 'image'" [image]="true" label="e-learning_content_image" formControlName="image"/>
    <mat-form-field class="w-full" *ngIf="contentForm.controls.type.value == 'image'">
      <mat-label>{{'e-learning_content_image_type' | translate}}</mat-label>
      <mat-select formControlName="imageType">
        <mat-option *ngFor="let imageType of imageTypes" [value]="imageType">
          {{'e-learning_content_image_' + imageType | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-input-form
      *ngIf="contentForm.controls.type.value == 'video'"
      [video]="true"
      (videoLength)="onReceiveVideoLength($event)"
      label="e-learning_content_video" formControlName="video"/>
    <div *ngIf="contentForm.controls.type.value == 'quiz'">
      <span *ngIf="contentId === 'create'" class="text-orange-400">
        {{'learning_admin_content_quiz_create_hint' | translate}}
      </span>
    </div>
    <mat-form-field class="w-full" *ngIf="contentForm.controls.type.value === 'text' || contentForm.controls.type.value === 'image' || contentForm.controls.type.value === 'video'">
      <mat-label>{{'reading_time' | translate}}</mat-label>
      <input matInput type="number" placeholder="{{ 'reading_time' | translate }}" formControlName="time" [disabled]="true" [readonly]="true">
    </mat-form-field>
    <div class="flex justify-end">
      <button mat-raised-button color="primary" type="submit" [disabled]="!contentForm.valid">
        {{'e-learning_content_save' | translate}}</button>
    </div>
  </form>
</div>
