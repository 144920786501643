import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user.component';
import {AgGridModule} from "ag-grid-angular";
import {MatButtonModule} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [
    UserComponent
  ],
  imports: [
    CommonModule,
    AgGridModule,
    MatButtonModule,
    TranslateModule
  ]
})
export class UserModule { }
