import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {AuthService} from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }
  canActivate(state: RouterStateSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Observable<boolean>(obs => {
      this.authService.isSignedIn().subscribe((user) => {
        if (user) {
          if (state.url.toString() === 'login') {
            this.router.navigate(['/']).then();
            obs.next(false);
          } else {
            obs.next(true);
          }
        } else {
          if (state.url.toString() !== 'login') {
            this.router.navigate(['/login']).then();
            obs.next(false);
          } else {
            obs.next(true);
          }
        }
      });
    });
  }
}
