import {Component, Input, OnInit} from '@angular/core';
import {ELearningService} from "../../../services/e-learning.service";
import {Content} from "../../../models/content.model";
import {Router} from "@angular/router";
import {ConfirmDialogComponent} from "../../../common/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  @Input() trainingId: string = '';
  @Input() chapterId: string = '';

  contents: Content[] = [];

  constructor(
    private eLearningService: ELearningService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.getContents();
  }

  getContents() {
    this.eLearningService.getContents(this.trainingId, this.chapterId).then(contentsSnap => {
      const contents: Content[] = [];
      contentsSnap.docs.map(contentSnap => {
        contents.push(contentSnap.data());
      });
      console.log(contents);
      this.contents = contents;
    });
  }

  addContent() {
    this.router.navigate(['/e-learning/training', this.trainingId, 'chapter', this.chapterId, 'content', 'create']).then();
  }

  goToContent(event: any, contentId: string) {
    event.stopPropagation();
    this.router.navigate(['/e-learning/training', this.trainingId, 'chapter', this.chapterId, "content", contentId]).then();
  }

  goToQuizzes(event: any, contentId: string) {
    event.stopPropagation();
    this.router.navigate(['/e-learning/training', this.trainingId, 'chapter', this.chapterId, "content", contentId, 'quiz']).then();
  }

  deleteContent(event: any, contentId: string) {
    event.stopPropagation();
    this.openConfirmDeleteDialog(contentId);
  }

  openConfirmDeleteDialog(contentId: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: 'e-learning_admin_content_confirm_delete',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if (result && result.confirmed) {
        this.eLearningService.deleteContent(this.trainingId, this.chapterId, contentId).then(() => {
          this.getContents();
        });
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.contents, event.previousIndex, event.currentIndex);
    this.contents.forEach((content, index) => {
      content.order = index + 1;
    });
  }

  saveSort() {
    const promises: any[] = [];
    this.contents.forEach((content) => {
      promises.push(new Promise((resolve) => {
        this.eLearningService.updateContentOrder(this.trainingId, this.chapterId, content).then(() => {
          resolve(null);
        });
      }));
    });
    Promise.all(promises).then(() => {
      this.openSnackBar(this.translate.instant('e-learning_admin_content_sort_success'));
      this.getContents();
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 5 * 1000,
    });
  }

}
