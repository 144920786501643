import {Component, OnInit} from '@angular/core';
import {ELearningService} from "../../../services/e-learning.service";
import {MatDialog} from "@angular/material/dialog";
import {SimpleFormDialogComponent} from "../../../common/simple-form-dialog/simple-form-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {Simple} from "../../../models/simple.model";
import {ConfirmDialogComponent} from "../../../common/confirm-dialog/confirm-dialog.component";
import {LanguageService} from "../../../services/language.service";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  trainingCategories: any[] = [];
  displayedColumns: string[] = ['name', 'actions'];
  dataSource: any;
  targetGroups: any[] = [];
  dataSourceGroups: any;

  constructor(
    private eLearningService: ELearningService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
    this.getTrainingCategories();
  }

  getTrainingCategories() {
    this.eLearningService.getTrainingCategories().then(cat => {
      const trainingCategories: Simple[] = [];
      cat.docs.map(c => {
        trainingCategories.push(c.data());
      });
      this.trainingCategories = trainingCategories;
      this.dataSource = this.trainingCategories;
    });
  }

  addTrainingCategory() {
    const dialogRef = this.dialog.open(SimpleFormDialogComponent, {
      data: {
        data: new Simple('', this.languageService.prepareLanguageObject()),
        title: this.translate.instant('e-learning_admin_training_category_add'),
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.eLearningService.addTrainingCategory(result).then(() => {
          this.getTrainingCategories();
        });
      }
    });
  }

  updateTrainingCategory(cat: Simple) {
    const dialogRef = this.dialog.open(SimpleFormDialogComponent, {
      data: {
        data: cat,
        title: this.translate.instant('e-learning_admin_training_category_edit'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      console.log(result);
      if (result) {
        this.eLearningService.updateTrainingCategory(result).then(() => {
          this.getTrainingCategories();
        });
      } else {
        this.getTrainingCategories();
      }
    });
  }

  deleteTrainingCategory(event: any, cat: Simple) {
    event.stopPropagation();
    this.openConfirmDeleteCategoryDialog(cat);
  }

  openConfirmDeleteCategoryDialog(cat: Simple): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if (result && result.confirmed) {
        this.eLearningService.deleteTrainingCategory(cat.id!).then(() => {
          this.getTrainingCategories();
        });
      }
    });
  }

  getTargetGroups() {
    this.eLearningService.getTargetGroups().then(cat => {
      const targetGroups: Simple[] = [];
      cat.docs.map(c => {
        targetGroups.push(c.data());
      });
      this.targetGroups = targetGroups;
      this.dataSourceGroups = this.targetGroups;
    });
  }

  addTargetGroup() {
    const dialogRef = this.dialog.open(SimpleFormDialogComponent, {
      data: {
        data: new Simple('', this.languageService.prepareLanguageObject()),
        title: this.translate.instant('e-learning_admin_target_group_add'),
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.eLearningService.addTargetGroup(result).then(() => {
          this.getTargetGroups();
        });
      }
    });
  }

  updateTargetGroup(cat: Simple) {
    const dialogRef = this.dialog.open(SimpleFormDialogComponent, {
      data: {
        data: cat,
        title: this.translate.instant('e-learning_admin_target_group_edit'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      console.log(result);
      if (result) {
        this.eLearningService.updateTargetGroup(result).then(() => {
          this.getTargetGroups();
        });
      } else {
        this.getTargetGroups();
      }
    });
  }

  deleteTargetGroup(event: any, cat: Simple) {
    event.stopPropagation();
    this.openConfirmDeleteGroupDialog(cat);
  }

  openConfirmDeleteGroupDialog(cat: Simple): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if (result && result.confirmed) {
        this.eLearningService.deleteTargetGroup(cat.id!).then(() => {
          this.getTargetGroups();
        });
      }
    });
  }
}
