import { Component } from '@angular/core';

@Component({
  selector: 'app-e-learning-reports',
  templateUrl: './e-learning-reports.component.html',
  styleUrls: ['./e-learning-reports.component.scss']
})
export class ELearningReportsComponent {

}
