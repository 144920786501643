import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { FileUpload } from "../../../models/file-upload.model";
import {finalize} from "rxjs/operators";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {InputFormComponent} from "../input-form.component";

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})

export class UploadImageComponent {
  private basePath = '/uploads';
  selectedImage?: File;
  currentImageUpload?: FileUpload;
  percentage = 0;

  @ViewChild("inputForm", { static: false }) inputFormComponent!: InputFormComponent;
  @Output() receivedDownloadUrl = new EventEmitter<string>();
  @Input() reference = 'trainings';
  @Input() referenceId = '';

  constructor(private storage: AngularFireStorage) { }

  selectImage(event: any): void {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (
        selectedFile.type.startsWith('image/') &&
        /\.(jpg|jpeg|png|gif|webp)$/i.test(selectedFile.name)
      ) {
        this.selectedImage = selectedFile;
        const uploadButton = document.getElementById('uploadButton') as HTMLInputElement;
        if (uploadButton) {
          uploadButton.disabled = false;
        }
      } else {
        console.log('Please select a valid image file (JPEG, PNG, GIF, WebP).');
      }
    } else {
      console.log('No file selected.');
    }
  }

  pushFileToStorage(fileUpload: FileUpload) {
    const filePath = `${this.basePath}/${fileUpload.reference}/${fileUpload.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    uploadTask.snapshotChanges().pipe(
      finalize((): any => {
        storageRef.getDownloadURL().subscribe((downloadURL: any) => {
          fileUpload.url = downloadURL;
          this.receivedDownloadUrl.emit(downloadURL);
        });
      })
    ).subscribe();
    return uploadTask.percentageChanges();
  }

  uploadImage(): void {
    if (this.selectedImage) {
      let fileName = this.selectedImage.name;
      const extensionMatch = fileName.split('.').at(-1)!.toLowerCase();
      if (extensionMatch && this.referenceId) {
        fileName = this.referenceId + '.' + extensionMatch;
      }
      this.currentImageUpload = new FileUpload('', fileName, '', this.selectedImage, this.reference);
      this.pushFileToStorage(this.currentImageUpload).subscribe(
        percentage => {
          this.percentage = Math.round(percentage ? percentage : 0);
        },
        error => {
          console.log(error);
        },
        () => {
          this.currentImageUpload = undefined;
          this.percentage = 0;
          const fileInput = document.getElementById('fileInput') as HTMLInputElement;
          fileInput.value = '';
          this.selectedImage = undefined;
        }
      );
    }
  }

}
