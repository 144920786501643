<ag-grid-angular
        class="ag-theme-alpine w-full h-[850px]"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData$"
        [rowSelection]="'multiple'"
        [animateRows]="true"
        [enableCellTextSelection]="true"
        (firstDataRendered)="onFirstDataRendered($event)"
        (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClicked($event)"
        (cellEditingStopped)="onCellEditingStopped($event)"
></ag-grid-angular>
