<div class="flex">
  <h1>{{'e-learning_training_overview' | translate}}</h1>
  <button routerLink="/e-learning/settings" class="ml-auto" mat-icon-button>
    <mat-icon>settings</mat-icon>
  </button>
</div>

<div class="flex flex-wrap gap-4">
  <app-single-training [createNew]="true"></app-single-training>
  <app-single-training
    *ngFor="let training of trainings"
    [training]="training"
    (trainingDelete)="onTrainingDelete()"></app-single-training>
</div>
