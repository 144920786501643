import {
  Component, EventEmitter,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {LanguageService} from "../../services/language.service";
import {MatTabGroup} from "@angular/material/tabs";

@Component({
  selector: 'app-input-form',
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFormComponent),
      multi: true
    }
  ]
})

export class InputFormComponent implements ControlValueAccessor, OnInit {
  @ViewChild("tabGroup", { static: false }) tabGroup: MatTabGroup | undefined;
  @HostBinding('attr.id')
  externalId = '';
  hasError = false;
  private control: FormControl | null = null;

  @Input() label = 'name';
  @Input() rows = 0;
  @Input() required = false;
  @Input() editor = false;
  @Input() image = false;
  @Input() video = false;
  @Input() reference = 'trainings';
  @Input() referenceId = '';
  @Output() videoLength: EventEmitter<number> = new EventEmitter<number>();
  @Input()
  set id(value: any) {
    this._ID = value;
    this.externalId = '';
  }

  get id() {
    return this._ID;
  }
  private _ID = '';
  @Input('value') _value = {};
  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this._value;
  }

  set value(val: any) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
    this.valueChanged.emit(val);
    this.hasError = this.showError;
  }

  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();

  languages: string[] = [];

  constructor(
    // @Optional() @Host() @SkipSelf() private control: NgControl,
    private languageService: LanguageService,
    private injector: Injector,
  ) {
  }

  ngOnInit(): void {
    this.languageService.getAllTranslation().then(() => {
      this.languages = this.languageService.languages;
    });
  }

  // The form control is only set after initialization
  ngAfterViewInit(): void {
    const ngControl: NgControl | null = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control = ngControl.control as FormControl;
    } else {
      // Component is missing form control binding
    }
    if (this.tabGroup) {
      this.tabGroup!.selectedIndex = this.languageService.languages.indexOf('de');
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.value = this.matchLanguages(value);
    }
  }

  matchLanguages(value: any) {
    this.languageService.languages.forEach(lang => {
      if (!value[lang]) {
        value[lang] = '';
      }
    });
    return value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChangeInputValue(event: any, lang: string) {
    this.value[lang] = event.target.value;
    this.value = this.value;
  }

  onChangeEditorValue(content: any, lang: string) {
    this.value[lang] = content;
    this.value = this.value;
  }

  onReceiveDownloadUrl(url: string, lang:string) {
    this.value[lang] = url;
  }

  onReceiveVideoDuration(duration: number, lang:string) {
    if (lang === 'de') {
      this.videoLength.emit(duration);
    }
  }

  public get invalid(): boolean {
    if (this.control !== null && this.control !== undefined) {
      return this.control.invalid || false;
    } else {
      return false;
    }
  }

  public get showError(): boolean {
    if (!this.control) {
      return false;
    }

    const { dirty, touched } = this.control;

    if (this.invalid) {
      if (dirty === null && touched === null) {
        return false;
      } else if (dirty === null) {
        return touched || false;
      } else if (touched === null) {
        return dirty || false;
      } else {
        return dirty || touched;
      }
    } else {
      return false;
    }
  }
}
