import { Injectable } from '@angular/core';
import {addDoc, collection, doc, getDocs, getFirestore, updateDoc} from "@angular/fire/firestore";
import {trainingConverter} from "../models/training.model";
import {User, userConverter} from "../models/user.model";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  db = getFirestore();
  userRef = collection(this.db, "users");

  constructor() { }

  getUsers() {
    return getDocs(this.userRef.withConverter(userConverter));
  }

  updateUser(user: User) {
    const ref = doc(this.db, "users", user.id!);
    return updateDoc(ref, userConverter.toFirestore(user));
  }

  addUser(user: User) {
    return addDoc(this.userRef, userConverter.toFirestore(user));
  }
}
