import {Component, inject, ViewChild} from '@angular/core';
import {CellClickedEvent, ColDef, GridReadyEvent} from "ag-grid-community";
import {Observable} from "rxjs";
import {AgGridAngular} from "ag-grid-angular";
import {HttpClient} from "@angular/common/http";
import {UserService} from "../../services/user.service";
import {User} from "../../models/user.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {

  private userService: UserService = inject(UserService);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private translate: TranslateService = inject(TranslateService);

  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    { field: 'email'},
    { field: 'firstName'},
    { field: 'lastName' },
    { field: 'group' },
    { field: 'paidUser'},
    { field: 'storeRegistration' },
    {
      field: 'role',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['', 'groupAdmin', 'globalAdmin'],
        valueListGap: 0
      }
    },
    {
      field: 'hasTachographPermission',
      editable: true,
    },
    {
      field: 'hasELearningPermission',
      editable: true,
    }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };

  public rowData$!: User[];

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  // Example load data from server
  onGridReady(params: GridReadyEvent) {
    this.userService.getUsers().then((users) => {
      this.rowData$ = users.docs.map((user) => user.data() as User);
    });
  }

  // Example of consuming Grid Event
  onCellClicked( e: CellClickedEvent): void {
    console.log('cellClicked', e);
  }

  // Example of consuming Grid Event
  onCellEditingStopped( e: CellClickedEvent): void {
    console.log('onCellEditingStopped', e);
    if (e.column.getColId() === "role" ||
      e.column.getColId() === "hasTachographPermission" ||
      e.column.getColId() === "hasELearningPermission") {
      this.userService.updateUser(e.data).then(() => {
        this.openSnackBar(this.translate.instant("user_updated_permission"));
      });
    }
  }

  // Example using Grid's API
  clearSelection(): void {
    this.agGrid.api.deselectAll();
  }

  addUser() {
    /* this.userService.addUser().then(() => {
      this.openSnackBar(this.translate.instant("user_added"));
    }); */
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 15 * 1000,
    });
  }

}
