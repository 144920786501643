import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NavComponent} from "./layout/nav/nav.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {AuthComponent} from "./pages/auth/auth.component";
import {AuthGuard} from "./guards/auth.guard";
import {MediaComponent} from "./pages/device/media/media.component";
import {ELearningComponent} from "./pages/e-learning/e-learning.component";
import {TrainingFormComponent} from "./pages/e-learning/trainings/training-form/training-form.component";
import {SettingsComponent} from "./pages/e-learning/settings/settings.component";
import {ChaptersComponent} from "./pages/e-learning/chapters/chapters.component";
import {ChapterFormComponent} from "./pages/e-learning/chapters/chapter-form/chapter-form.component";
import {ContentFormComponent} from "./pages/e-learning/content/content-form/content-form.component";
import {QuizComponent} from "./pages/e-learning/quiz/quiz.component";
import {QuizFormComponent} from "./pages/e-learning/quiz/quiz-form/quiz-form.component";
import {CertificatesComponent} from "./pages/e-learning/certificates/certificates.component";
import {ELearningReportsComponent} from "./pages/e-learning-reports/e-learning-reports.component";
import {UserComponent} from "./pages/user/user.component";
import {TranslationComponent} from "./pages/translation/translation.component";
import {DeviceContentComponent} from "./pages/device/device-content/device-content.component";

const routes: Routes = [
  {
    path: '',
    component: NavComponent,
    children: [
      { path: '', component: DashboardComponent, pathMatch: 'full'},
      { path: 'user', component: UserComponent},
      { path: 'translation', component: TranslationComponent},
      { path: 'e-learning-reports', component: ELearningReportsComponent},
      { path: 'device', component: MediaComponent},
      { path: 'device/media', component: MediaComponent},
      { path: 'device/content/:id', component: DeviceContentComponent},
      { path: 'e-learning', component: ELearningComponent},
      { path: 'e-learning/settings', component: SettingsComponent},
      { path: 'e-learning/training/create', component: TrainingFormComponent},
      { path: 'e-learning/training/:id', component: TrainingFormComponent},
      { path: 'e-learning/training/:id/certificate', component: CertificatesComponent },
      { path: 'e-learning/training/:id/chapter', component: ChaptersComponent},
      { path: 'e-learning/training/:id/chapter/create', component: ChapterFormComponent},
      { path: 'e-learning/training/:id/chapter/:chapterId', component: ChapterFormComponent},
      { path: 'e-learning/training/:id/chapter/:chapterId/content/create', component: ContentFormComponent},
      { path: 'e-learning/training/:id/chapter/:chapterId/content/:contentId', component: ContentFormComponent},
      { path: 'e-learning/training/:id/chapter/:chapterId/content/:contentId/quiz', component: QuizComponent},
      { path: 'e-learning/training/:id/chapter/:chapterId/content/:contentId/quiz/create', component: QuizFormComponent},
      { path: 'e-learning/training/:id/chapter/:chapterId/content/:contentId/quiz/:quizId', component: QuizFormComponent},
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: AuthComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
