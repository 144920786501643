import { NgModule } from '@angular/core';
import {InputFormComponent} from "./input-form.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatTabsModule} from "@angular/material/tabs";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";
import {QuillEditorComponent} from "ngx-quill";
import { UploadImageComponent } from './upload-image/upload-image.component';
import { UploadVideoComponent } from './upload-video/upload-video.component';
import {MatButtonModule} from "@angular/material/button";
import {MatProgressBarModule} from "@angular/material/progress-bar";
@NgModule({
  declarations: [
    InputFormComponent,
    UploadImageComponent,
    UploadVideoComponent
  ],
  imports: [
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    QuillEditorComponent,
    MatButtonModule,
    MatProgressBarModule
  ],
  exports: [
    InputFormComponent
  ]
})
export class InputFormModule { }
